import { Stakeholders } from "utils/StakeholdersInfo"
import StakeholderImageCard from "components/StakeholderImageCard"
import * as React from "react"

function SelectionAndInterviewPanel() {
	return (
		<div className="selectionAndInterviewPanel__container">
			<div className="selectionAndInterviewPanel__heading">Selection and Interview Panel for 2021-2022</div>
			<div className="selectionAndInterviewPanel__row">
				<StakeholderImageCard {...Stakeholders.ambrin} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.aruniya} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.akshay} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.cibi} className="selectionAndInterviewPanel__column"/>
			</div>

			<div className="selectionAndInterviewPanel__row">
				<StakeholderImageCard {...Stakeholders.devah} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.diksha} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.lakshmis} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.pawan} className="selectionAndInterviewPanel__column"/>
			</div>

			<div className="selectionAndInterviewPanel__row">
				<StakeholderImageCard {...Stakeholders.pranav} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.prashanthi} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.sowmya} className="selectionAndInterviewPanel__column"/>
			</div>
			<div className="selectionAndInterviewPanel__row">
				<StakeholderImageCard {...Stakeholders.vivek} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.wahaj} className="selectionAndInterviewPanel__column"/>
				<StakeholderImageCard {...Stakeholders.zafar} className="selectionAndInterviewPanel__column"/>
			</div>
		</div>
	)
}

export default SelectionAndInterviewPanel
