import React from "react"
import { SocialIcon } from "react-social-icons"

function Stakeholder(props) {
	return (		
		<div className="stakeholder__container" data-testid={`${props.testid}`}>
			<div className="stakeholder__info">
				<div className="stakeholder__name">{props.name}{(props.currentDesignation || props.education) && ","}&nbsp;</div>
				{(props.currentDesignation || props.education) && <div className="stakeholder__designation">{props.currentDesignation === "" || props.currentDesignation.toLowerCase() === "student" ? props.education : props.currentDesignation}</div>}
			</div>

			{props.linkedin 
				&& 
				<div className="stakeholder__link">
					<SocialIcon style={{ height: 40, width: 40, alignContent: "center"}} fgColor="#000000" bgColor="#ffffff" url={props.linkedin} target={"_blank"} />
				</div>
			}
			
		</div>)
}

export default Stakeholder