import React from "react"

const Donate = () => (
	<div className="page" data-testid="donate">
		<div className="col-9 page__container">
			<p>
				Thank you for your generosity! Funds collected through donations will be used as the corpus whose interest will be used as the scholarship amount (50000 INR) as well as for the organisational expense (approx. 10000 INR recurring annually)
			</p>
			<br />
			<p>
				You can donate through one of our online fundraisers:
			</p>
			<br />
			<ul className="page__list">
				<li><a href="https://milaap.org/fundraisers/jspf-nitt" className="page__link" target='_blank' rel='noreferrer'>https://milaap.org/fundraisers/jspf-nitt</a> (For Indian donation)</li>
				<li><a href="https://www.gofundme.com/f/jspf-nitt-2020" className="page__link" target='_blank' rel='noreferrer'>https://www.gofundme.com/f/jspf-nitt-2020</a> (other currency)</li>
				<li>
					For direct bank transfer:
					<ul className="page__list page__list--inner">
						<li>Account name: The Academy for Holistic Education Awareness and Development (AHEAD) Trust</li>
						<li>Account number: 618305029930</li>
						<li>IFSC code: ICIC0006183</li>
					</ul>
				</li>
			</ul>
		</div>
	</div>
)

export default Donate