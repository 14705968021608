import React from "react"
import { useEffect } from "react"

const WorkWithUs = () => {
	useEffect(() => {
		document.title = "Work with us"
	}, [])

	return (
		<div className="page" data-testid="work-with-us">
			<div className="col-9 page__container">
				<p>
					If you are interested in corporate partnership, please drop a mail
					with details to{" "}
					<a
						className="page__link"
						href="mailto:info@jrscholarship.org"
						target="_blank"
						rel="noreferrer"
					>
						info@jrscholarship.org
					</a>
				</p>
				<div className="wwu__image-container">
					<div className="wwu__left-container wwu__font-black">
						Career Development Partner
					</div>
					<div>
						<img
							data-testid="wwu-image"
							className="wwu__img"
							src="images/img-work-with-us.png"
						></img>
					</div>
				</div>

				<hr className="divider" />
				<div className="wws__volunteer-header wwu__font-black">
					Volunteer with us
				</div>
				<p>
					<b className="wwu__font-black">Current openings:</b>
				</p>
				<ul className="wwu__list-container">
					<li>
						<span className="wwu__font-orange">[Join an existing team]</span>{" "}
						<span className="wwu__font-black">Panelist Relations</span>
					</li>
					<ul className="wwu__list-container">
						<li>
							Job Description:{" "}
							<a
								data-testid="wwu-jd1-1"
								className="page__link"
								href="https://docs.google.com/presentation/d/1xtv1vmy4-TQFs0TkPlhgShFfYnhsnMzXAystkl9Zlro/edit?usp=sharing"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
						<li>
							Apply at:{" "}
							<a
								data-testid="wwu-jd1-2"
								className="page__link"
								href="https://docs.google.com/forms/d/e/1FAIpQLSfboNGezEGQ9HG2Y0WK0RQHZwdPbmxSHmjNv6HwbznjHROdqw/viewform"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
					</ul>
					<li>
						<span className="wwu__font-orange">[Join an existing team]</span>{" "}
						<span className="wwu__font-black">Community Engagement</span>
					</li>
					<ul className="wwu__list-container">
						<li>
							Job Description:{" "}
							<a
								data-testid="wwu-jd2-1"
								className="page__link"
								href="https://docs.google.com/presentation/d/1JcSuXO0imrokZ2oeEUs3NAO7htNuhNX5XcatYkwIJsw/edit?usp=sharing"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
						<li>
							Apply at:{" "}
							<a
								data-testid="wwu-jd2-2"
								className="page__link"
								href="https://docs.google.com/forms/d/e/1FAIpQLSfboNGezEGQ9HG2Y0WK0RQHZwdPbmxSHmjNv6HwbznjHROdqw/viewform"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
					</ul>
					<li>
						<span className="wwu__font-orange">[Join an existing team]</span>{" "}
						<span className="wwu__font-black">Diversity and Inclusion</span>
					</li>
					<ul className="wwu__list-container">
						<li>
							Job Description:{" "}
							<a
								data-testid="wwu-jd3-1"
								className="page__link"
								href="https://docs.google.com/presentation/d/1TrO8Bvfpe78GVxxH4gvBe6Vsy8AiCzn-Z4D9AUU4eps/edit?usp=sharing"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
						<li>
							Apply at:{" "}
							<a
								data-testid="wwu-jd3-2"
								className="page__link"
								href="https://docs.google.com/forms/d/e/1FAIpQLSfboNGezEGQ9HG2Y0WK0RQHZwdPbmxSHmjNv6HwbznjHROdqw/viewform"
								target="_blank"
								rel="noreferrer"
							>
								Click here
							</a>
						</li>
					</ul>
				</ul>
				<div className="wwu__font-black">
					General volunteering application:{" "}
					<a
						className="page__link"
						href="https://docs.google.com/forms/d/e/1FAIpQLSeXSuajeNSvP93yI_u5zxlyFK4Y9wEp_asOwCodO8_12H3BDA/viewform"
						target="_blank"
						rel="noreferrer"
					>
						apply here
					</a>
				</div>
			</div>
		</div>
	)
}
export default WorkWithUs
