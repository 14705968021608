import * as React from "react"
import { useEffect } from "react"
import obj_1 from "../assets/objectives/img-obj_1.png"
import obj_2 from "../assets/objectives/img-obj_2.png"
import obj_3 from "../assets/objectives/img-obj_3.png"
import obj_4 from "../assets/objectives/img-obj_4.png"
import obj_5 from "../assets/objectives/img-obj_5.png"

function Objectives() {
	useEffect(() => {
		document.title = "Our Objectives"
	}, [])
	return (
		<div className="page" data-testid="objectives">
			<div className="objectives__mv-text-box">
				<div>
					<div className="objectives__mv-title">MISSION STATEMENT</div>
					<div className="objectives__mv-text">
						To nudge students towards achieving clarity about their strengths
						and passion
					</div>
					<div className="objectives__mv-title">OUR VISION</div>
					<div className="objectives__mv-text">
						To bring together and nurture a lineage of leaders and change-makers
						for the betterment of the community and society
					</div>
				</div>
			</div>
			<div className="col-8 page__container">
				Jitheshraj Scholarship for promising freshmen (JSPF) is designed to
				achieve five objectives:
				<div className="objectives__page-div">
					<img className="objectives__image" src={obj_1}></img>
					<div>
						<h3>
							Accelerate the process of achieving clarity about ones passion,
							interests and skills
						</h3>
						There is a rising trend in students feeling directionless after
						graduation and/or dissatisfied with their job at hand. JSPF is
						designed to nudge students towards exploring various interests
						open-mindedly while they can leverage the myriad resources that
						college life in India provides. We believe that this will help steer
						students towards a career path that can bring together their innate
						abilities and their acquired competencies - a career path they truly
						feel they belong at.
					</div>
				</div>
				<div className="objectives__page-div">
					<div>
						<h3>Kindle Critical Technological Awareness</h3>
						<p>
							With the world rapidly adopting technology in all walks of life,
							technical literacy and the ability to critically analyse the
							impact of technology are imperative. JSPF, through its application
							process, instigates a holistic dissection of technology in an
							attempt to make Responsible problem solving the norm.
						</p>
					</div>
					<img className="objectives__image" src={obj_2}></img>
				</div>
				<div className="objectives__page-div">
					<img className="objectives__image" src={obj_3}></img>
					<div>
						<h3>Expose students to world-class talent gauging systems</h3>
						JSPF strives to prepare students for the application, essay and
						interview processes often associated with global scholarships,
						fellowships and graduate school applications. We continuously
						improve our selection process to be at par with global trends and
						derive inspiration from various established processes including that
						of Rhodes, Schwarzman and Chevening Scholarships, Team4Tech
						volunteer recruitment etc.
					</div>
				</div>
				<div className="objectives__page-div">
					<div>
						<h3>Provide relatable role models for any given goal</h3>
						<p>
							Through the scholarship, we intend to bring together an accessible
							alumni pool whose guidance and mentorship applicants can avail.
							Everything from application process to career advice - there is a
							go-to alumnus.
						</p>
					</div>
					<img className="objectives__image" src={obj_4}></img>
				</div>
				<div className="objectives__page-div">
					<img className="objectives__image" src={obj_5}></img>
					<div>
						<h3>
							{" "}
							Build a sense of moral responsibility towards one’s community and
							the society
						</h3>
						In this increasingly fast and connected world, there is an inherent
						need to develop a strong moral compass on when to speak up and act.
						Our application process is tailored to develop and recognise
						qualities such as mindfulness, forward thinking and empathy -
						qualities we believe are essential in change makers, especially when
						they have to question the status quo and resist going-with-the-flow.
						We aim to nurture active introspection in our applicants and help
						them serve as a conscientious and model citizens.
					</div>
				</div>
			</div>
			<div className="objectives__text-box">
				<div className="objectives__title">
					<div className="objectives__text">
						We aim to reward not the smartest of students
					</div>
					<div className="objectives__text">but those who show</div>
					<div className="objectives__text">
						growth mindset and a promising trajectory
					</div>
				</div>
			</div>
		</div>
	)
}

export default Objectives
