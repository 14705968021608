import ReactGA from "react-ga4"

ReactGA.initialize([{
	trackingId: process.env.GA_TRACKING_ID || "G-7VGQDYT5X2",
	gaOptions: {
		send_page_view: false,
		allowAdFeatures: false,
		ignore_referrer: true
	}
}])

export function GaTrack(type = {}) {
	ReactGA.send(type)
}