import SlideShow from "components/Slideshow"
import React, {useEffect} from "react"
import { Link } from "react-router-dom"
import { FAQImages } from "utils/SlideShowImages"
import useWindowDimensions from "hooks/useWindowDimensions"
import { IndicatorType } from "../utils/SlideshowIndicator"

const Faq = () => {
	const windowSize = useWindowDimensions()
	useEffect(() => {
		document.title = "Frequently Asked Questions"
	}, [])
	return (
		<div className="page" data-testid="faq">
			<div className="page__container col-9">
				<h1 className="page__center-text">FREQUENTLY ASKED QUESTIONS</h1>
				
				<u><strong>Eligibility:</strong></u>
				<ol>
					<li>
						<strong><font color="#2a2a2a">Can I apply for the scholarship?</font></strong>
						<br/>
						<em>If you are currently a first year undergraduate student in India and have not applied to JSPF in the past, yes, you are eligible to apply. At the moment, we do not consider applications from re-applicants.</em>
					</li>
					<br />
				</ol>

				<u><strong>Contests:</strong></u>
				<ol>
					<li>
						<strong><font color="#2a2a2a">Does volunteering for college festivals and symposiums as a co-ordinator/manager count for participation criteria?</font></strong>
						<br/>
						<em>For points 2 and 3 mentioned in Application Process, you are expected to compete in a contest. Volunteering to organise a festival or symposium does not qualify for these criteria.</em>
					</li>
					<br />
					<li>
						<strong><font color="#2a2a2a">Can I participate in Sports events for the contest(s) criteria?</font></strong>
						<br/>
						<em>You can. But please note that it is participation in events related to STEM field that will get you additional points.</em>
					</li>
					<br />
					<li>
						<strong><font color="#2a2a2a">Does winning a contest give me extra points compared to just participating in a contest?</font></strong>
						<br/>
						<em>It does not. We value exploration and in that regard, participation is as valuable as winning.</em>
					</li>
				</ol>
				<p>For points 2 and 3 mentioned in <Link data-testid="link-to-requirements" to="/requirements" className="page__link">Application process</Link>, you are expected to compete in a contest. Volunteering to organise a festival or symposium does not qualify for these criteria.</p>
				<br />
				<u><strong>Essays:</strong></u>
				<ol>
					<li>
						<strong><font color="#2a2a2a">Will the mentors write my essays?</font></strong>
						<br/>
						<em>Mentors will not write your essays. They will help fine tune and give guidance on making it the best possible version of your essay. They will make sure that the essay is still your voice and words. They will also help with any other generic questions you might have about the application criteria.</em>
					</li>
					<br />
				</ol>

				<u><strong>Project:</strong></u>
				<ol>
					<li>
						<strong><font color="#2a2a2a">What exactly is expected for the project mentioned in the application requirement?</font></strong>
						<br/>
						<em>As mentioned in the wesbite, you can either work on a hobby project or a project with some organisation. Projects pertaining to a STEM field generally have an edge. There is no specific requirement in terms of the size of the project, as long as it helps you understand your (dis)interest in a certain field. Your submission will be gauged based on how much personal learning it involved. Projects with tangible outcomes and/or with informative study/analysis are accepted.</em>
					</li>
					<br />
				</ol>

				<u><strong>Selection and interviews:</strong></u>
				<ol>
					<li>
						<strong><font color="#2a2a2a">Who will the panelists be?</font></strong>
						<br/>
						<em>Panelists will be established alumni of various engineering, design and architecture institutes in India. We are confirming on the exact names at the earliest possible. The <Link data-testid="link-to-panelists" to="/selection-and-interview-panel" className="page__link">Panelists</Link> page will be updated as and when confirmed. </em>
					</li>
					<br />
				</ol>
				<div>
					<SlideShow 
						Images={FAQImages} 
						duration={4000}
						indicator={IndicatorType.ORDERED}
						containerWidth={windowSize.width < 1080 ? "800px" :"600px"}
						containerHeight={windowSize.width < 1080 ? "70vh" :"90vh"}
						slideShowWidth={windowSize.width < 1080 ? "95vw" :"60vw"}/>
				</div>
				<div>
					<div style={{height: "20px", overflow: "hidden", width: "100%"}}></div>
					<hr style={{width: "100%"}}></hr>
					<div style={{height: "20px", overflow: "hidden", width: "100%"}}></div>
				</div>
				<div className="page__center-text">
					<font color="#2a2a2a">Have some question you do not see here?</font>
					<br />
					<font>Check out our <Link data-testid="link-to-resources" to="/resources" className="page__link">Resources</Link> page</font>
				</div>
			</div>
		</div>
	)
}

export default Faq