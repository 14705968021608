import { PRONOUNS } from "./Pronouns"

//Add in alphabetical order
export const Stakeholders = {
	"aadarsh": {
		"name": "Aadarsh A",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/aadarsh-a",
		"testid": "aadarsh"
	},
	"abhishek": {
		"name": "K S Abhishek",
		"currentDesignation": "Student",
		"education": "Mechanical Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/k-s-abhishek-a507921a4",
		"testid": "abhishek"
	},
	"Ajays": {
		"name": "Ajay Sapkale",
		"currentDesignation": "Co-founder at Astrophilia Ed",
		"education": "B.Des, Industrial and Product Design",
		"pronouns": PRONOUNS.HE,
		"image": "img-ajay-sapkale.jpg",
		"linkedin": "https://www.linkedin.com/in/ajay-sapkale-2b06a5183",
		"testid": "Ajays"
	},
	"akhilesh": {
		"name": "Akhilesh C",
		"currentDesignation": "Student",
		"education": "Mechanical Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/akhilesh-chandrasekhar",
		"testid": "akhilesh"
	},
	"akriti": {
		"name": "Akriti Upadhyay",
		"currentDesignation": "MCA, 2023, NIT Trichy",
		"jspfDesignation": "Associate",
		"education": "MCA, 2023, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-akriti-upadhyay.jpg",
		"linkedin": "https://www.linkedin.com/in/akriti-upadhyay/",
		"testid": "akriti"
	},
	"akshay": {
		"name": "Akshay Vidwans",
		"currentDesignation": "Global Market Segment Lead at Envirologix",
		"education": "Mechanical, 2009, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-akshay-vidwans.jpeg",
		"linkedin": "https://www.linkedin.com/in/akshay-vidwans-90572756/",
		"testid": "akshay"
	},
	"akshaya": {
		"name": "Akshaya Subramanian",
		"currentDesignation": "Student",
		"education": "Instrumentation and Control Engineering",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/akshaya-subramanian-22020/",
		"testid": "akshaya"
	},
	"ambrin": {
		"name": "Ambrin",
		"currentDesignation": "VP, Guidance Tamil Nadu",
		"education": "Mechanical, 2011, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-ambrin.png",
		"linkedin": "https://www.linkedin.com/in/ambrinikram/",
		"testid": "ambrin"
	},
	"anagha": {
		"name": "Anagha Sethuraman",
		"currentDesignation": "Student",
		"education": "Electronics and Communication Engineering",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/anagha-sethuraman-192148179/",
		"testid": "anagha"
	},
	"anmol": {
		"name": "Anmol Panda",
		"currentDesignation": "MSc in Energy Sciences and Technology, ETH Zurich",
		"education": "B.Tech, Mechanical Engineering, NITT",
		"pronouns": PRONOUNS.SHE,
		"image": "img-anmol-panda.jpg",
		"linkedin": "https://www.linkedin.com/in/anmol-panda-368382179",
		"testid": "anmol"
	},
	"anushka": {
		"name": "Anushka Gangil",
		"currentDesignation": "Student",
		"education": "B.Des, Symbiosis Institute of Design",
		"pronouns": PRONOUNS.SHE,
		"linkedin": "https://www.linkedin.com/in/anushka-gangil-308780254/",
		"testid": "anushka"
	},
	"anushree": {
		"name": "Anushree Appandairajan",
		"jspfDesignation": "Senior Associate",
		"currentDesignation": "GET, John Deere",
		"education": "Electrical and Electronics Engineering",
		"pronouns": [PRONOUNS.SHE, PRONOUNS.THEY],
		"image": "img-anushree.jpeg",
		"linkedin": "https://www.linkedin.com/in/anushree-appandairajan-0aa134205",
		"testid": "anushree"
	},
	"aradhana": {
		"name": "Aradhana Mohan Parvathy",
		"currentDesignation": "Doctoral Student, Purdue University",
		"education": "EEE, 2020, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-aradhana.jpeg",
		"linkedin": "https://www.linkedin.com/in/aradhanamp/",
		"testid": "aradhana"
	},
	"aruniya": {
		"name": "Aruniya Selladurai",
		"currentDesignation": "AVP, TWCorp",
		"education": "CSE, 2012, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-aruniya-selladurai.jpeg",
		"linkedin": "https://www.linkedin.com/in/aruniya-selladurai-a8214a5b/",
		"testid": "aruniya"
	},
	"ashutosh": {
		"name": "Ashutosh Verma",
		"currentDesignation": "Student",
		"education": "Civil Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/ashutosh-verma-478ba5183/",
		"testid": "ashutosh"
	},
	"ashwath": {
		"name": "Ashwath V",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/ashwath-vasudevan-92282321a",
		"testid": "ashwath"
	},
	"ashwini": {
		"name": "Ashwini Petchiappan",
		"currentDesignation": "Programme Assistant, Dakshin Foundation",
		"education": "Civil, 2017, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/ashwinipetchiappan/",
		"testid": "ashwini"
	},
	"ayushi": {
		"name": "Ayushi Tiwari",
		"currentDesignation": "Project Scientist, CSIR",
		"education": "Civil, 2016, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/ayushitiwari/",
		"testid": "ayushi"
	},
	"bksivaraj": {
		"name": "B K Sivaraj",
		"currentDesignation": "Student",
		"education": "Metallurgical and Materials Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/sivaraj-b-k-b56123152/",
		"testid": "bksivaraj"
	},
	"chandhana": {
		"name": "Chandhana R",
		"jspfDesignation": "Associate",
		"currentDesignation": "Civil, 2023, NIT Trichy",
		"education": "Civil, 2023, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-chandhana-ravikumar.jpeg",
		"linkedin": "https://www.linkedin.com/in/chandhana-ravikumar-a048b61a3/",
		"testid": "chandhana"
	},
	"chendur": {
		"name": "Chendur Singaram",
		"currentDesignation": "Engineer, RefleXion Medical",
		"education": "Mechanical, 2017, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-chendur-singaram.jpeg",
		"linkedin": "https://www.linkedin.com/in/chendur-singaram/",
		"testid": "chendur"
	},
	"chirag": {
		"name": "Chirag Vaswani",
		"currentDesignation": "SDE2, Aurigo",
		"jspfDesignation": "Associate",
		"education": "ICE, 2020, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-chirag-vaswani.jpeg",
		"linkedin": "https://www.linkedin.com/in/chirag-vaswani-b63729128/",
		"testid": "chirag"
	},
	"cibi": {
		"name": "Cibi Pranav",
		"currentDesignation": "Digital Analyst/ Programmer, Arcadis",
		"education": "Civil, 2015, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-cibi-pranav.jpeg",
		"linkedin": "https://www.linkedin.com/in/cibi-pranav-72a66a51/",
		"testid": "cibi"
	},
	"darshan": {
		"name": "Darshan Maru",
		"jspfDesignation": "Senior Associate",
		"currentDesignation": "Product Manager",
		"education": "Metallurgical and Materials Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "img-darshan-maru.jpeg",
		"linkedin": "https://www.linkedin.com/in/darshanmaru/",
		"testid": "darshan"
	},
	"darshana": {
		"name": "Darshana V",
		"jspfDesignation": "Associate",
		"currentDesignation": "ICE, 2023, NIT Trichy",
		"education": "ICE, 2023, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-darshana-v.jpeg",
		"linkedin": "https://www.linkedin.com/in/darshana-v/",
		"testid": "darshana"
	},
	"deepaknd": {
		"name": "Deepak N D",
		"testid": "deepaknd"
	},
	"deepan": {
		"name": "Deepan Thiruppathy",
		"testid": "deepan"
	},
	"devah": {
		"name": "Devahdhanush V S",
		"currentDesignation": "PhD Candidate",
		"education": "Mechanical, 2015, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-devahdhanush-vs.jpeg",
		"linkedin": "https://www.linkedin.com/in/devahdhanush/",
		"testid": "devah"
	},
	"devb": {
		"name": "Dev Bhandari",
		"currentDesignation": "Student",
		"education": "Instrumentation and Control Engineering",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/dev-bhandari-51b462227",
		"testid": "devb"
	},
	"dharaneesh": {
		"name": "Dharaneesh S A",
		"currentDesignation": "Student",
		"education": "B.Tech, Madras Institute of Technology",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/anushka-gangil-308780254/",
		"testid": "dharaneesh"
	},
	"dhruv": {
		"name": "Dhruv Kamath",
		"currentDesignation": "Technical Product Manager, SandboxAQ",
		"education": "Production, 2016, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/ashwinipetchiappan/",
		"testid": "dhruv"
	},
	"diksha": {
		"name": "Diksha Gohlyan",
		"currentDesignation": "Engineer, Voxel AI",
		"education": "CSE, 2013, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-diksha-gohlyan.png",
		"linkedin": "https://www.linkedin.com/in/diksha-gohlyan-697b81a5/",
		"testid": "diksha"
	},
	"dranantharaman": {
		"name": "Dr. Anantharaman",
		"currentDesignation": "HOD, Chemical Engineering, Alumni-Institute Interaction Cell, NIT Trichy",
		"testid": "dranantharaman"
	},
	"drbakthavatsalam": {
		"name": "Dr. Bakthavatsalamn",
		"currentDesignation": "Training and Placement, CEESAT - NIT Trichy",
		"testid": "drbakthavatsalam"
	},
	"drruckmani": {
		"name": "Dr. Ruckmani Kandasamy",
		"currentDesignation": "Mother of Jitheshraj Periasamy",
		"testid": "drruckmani"
	},
	"drsubbaiyan": {
		"name": "Dr. Subbaiyan",
		"currentDesignation": "Architecture, Alumni-Institute Interaction Cell, NIT Trichy",
		"testid": "drsubbaiyan"
	},
	"gayatri": {
		"name": "Gayatri Jadav",
		"currentDesignation": "Student, Christ University",
		"jspfDesignation": "Senior Associate",
		"education": "BBA, Christ University, Bangalore",
		"pronouns": PRONOUNS.SHE,
		"image": "img-gayatri.jpeg",
		"linkedin": "https://www.linkedin.com/in/gayatri-jadhav-ab5856254/",
		"testid": "gayatri"
	},
	"gayathri": {
		"name": "Gayathri Rajesh",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-gayathri-rajesh.jpg",
		"linkedin": "https://www.linkedin.com/in/gayathri-rajesh-b84560228/",
		"testid": "gayathri"
	},
	"ghantasala": {
		"name": "Ghantasala Harshith",
		"jspfDesignation": "Associate",
		"currentDesignation": "Civil, 2024, NIT Trichy",
		"education": "Civil Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "img-ghantasala-harshith.jpg",
		"linkedin": "https://www.linkedin.com/in/harshith-ghantasala",
		"testid": "ghantasala"
	},
	"gudapati": {
		"name": "Gudapati Nitish",
		"currentDesignation": "Graduate Student at Stanford University",
		"education": "Mechanical Engineering, 2021, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/gudapati-nitish/",
		"testid": "gudapati"
	},
	"haemanth": {
		"name": "Haemanth V",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/haemanth-v-951176191/",
		"testid": "haemanth"
	},
	"harsh": {
		"name": "Harsh Deshpande",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/hdeshpande1211/",
		"testid": "harsh"
	},
	"harshavardhan": {
		"name": "Harshavardhan R",
		"jspfDesignation": "Associate",
		"currentDesignation": "Civil, 2025, NIT Trichy",
		"education": "Civil, 2025, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-harshavardhan-r.jpeg",
		"linkedin": "https://www.linkedin.com/in/harshavardhan-r-a1ab6524b/",
		"testid": "harshavardhan"
	},
	"harshinee": {
		"name": "Harshinee Murali",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-harshinee-murali.jpeg",
		"linkedin": "https://www.linkedin.com/in/harshinee-murali-50bab71b5/",
		"testid": "harshinee"
	},
	"himanshi": {
		"name": "Himanshi Gupta",
		"currentDesignation": "Graduate Student, Georgia Tech",
		"jspfDesignation": "Senior Associate",
		"education": "ECE, 2021, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-himanshi-gupta.jpeg",
		"linkedin": "https://www.linkedin.com/in/himanshi-gupta-469534160/",
		"testid": "himanshi"
	},
	"hrishit": {
		"name": "Hrishit Mitra",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-hrishit-mitra.jpg",
		"linkedin": "https://www.linkedin.com/in/hrishit-mitra-135399297/",
		"testid": "hrishit"
	},
	"ishani": {
		"name": "Ishani Srivastava",
		"currentDesignation": "Software Engineer, Google",
		"jspfDesignation": "Associate",
		"education": "CSE, 2021, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-ishani-srivastava.jpeg",
		"linkedin": "https://www.linkedin.com/in/ishani-srivastava-a38056164/",
		"testid": "ishani"
	},
	"jai": {
		"name": "Jai Kesav K R",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-jai-kesav-kr.png",
		"linkedin": "https://www.linkedin.com/in/jai-kesav-k-r-400870195/",
		"testid": "jai"
	},
	"jayamathangi": {
		"name": "Jayamathangi S",
		"currentDesignation": "Student",
		"education": "Electrical and Electronics Engineering",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/jayamathangi-s-3464a8184/",
		"testid": "jayamathangi"
	},
	"jeremiah": {
		"name": "Jeremiah Thomas",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/jeremiahthomas14/",
		"testid": "jeremiah"
	},
	"karthikeyan": {
		"name": "Karthikeyan Hariharan",
		"currentDesignation": "Lecturer, FAU Erlangen-Nürnberg",
		"education": "MME, 2020, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-karthikeyan.jpeg",
		"linkedin": "https://www.linkedin.com/in/karthikeyan-h-b48b30136/",
		"testid": "karthikeyan"
	},
	"kasarla": {
		"name": "Kasarla Mukthi",
		"currentDesignation": "Associate User Experience Designer",
		"education": "B.Des in Fashion Communication",
		"pronouns": PRONOUNS.SHE,
		"image": "img-kasarla-mukthi.jpg",
		"linkedin": "https://www.linkedin.com/in/mukthikasarla",
		"testid": "kasarla"
	},
	"kashif": {
		"name": "Kashif Sheikh",
		"currentDesignation": "Asst GM, AGPT",
		"education": "EEE, 2009, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-kashif-sheikh.jpeg",
		"linkedin": "https://www.linkedin.com/in/kashif-sheikh-ks/",
		"testid": "kashif"
	},
	"kirthik": {
		"name": "Kirthik M",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/kirthikm/",
		"testid": "kirthik"
	},
	"kishore": {
		"name": "Kishore V",
		"currentDesignation": "Technology Associate, Morgan Stanley",
		"education": "CSE, 2021, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/kishorehere/",
		"testid": "kishore"
	},
	"kotilingesh": {
		"name": "Kotilingesh",
		"currentDesignation": "Student",
		"education": "Mechanical Engineering",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/dev-bhandari-51b462227",
		"testid": "kotilingesh"
	},
	"lakshmi": {
		"name": "Lakshmi K Ajayan",
		"currentDesignation": "Architectural Graduate, fitzpatrick+Partners",
		"education": "Architecture, 2017, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/lakshmi-k-ajayan/",
		"testid": "lakshmi"
	},
	"lakshmim": {
		"name": "Lakshmi M",
		"jspfDesignation": "Associate",
		"currentDesignation": "ML Engineer, Pinterest",
		"education": "CSE, 2016, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-lakshmi-manoharan.jpeg",
		"linkedin": "https://www.linkedin.com/in/lakshmimanoharan/",
		"testid": "lakshmi-m"
	},
	"lakshmis": {
		"name": "Lakshmi S",
		"currentDesignation": "Faculty, Srishti Manipal",
		"education": "Architecture, 2014, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-lakshmi-s.jpeg",
		"linkedin": "https://www.linkedin.com/in/lakshmijayashree/",
		"testid": "lakshmi-s"
	},
	"madhav": {
		"name": "Madhav Aggarwal",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/madhav-aggarwal/",
		"testid": "madhav"
	},
	"madhumathi": {
		"name": "Madhumathi S",
		"currentDesignation": "Student",
		"education": "B.Des, DOT Schoool of Design",
		"pronouns": PRONOUNS.SHE,
		"image": "img-madhumathi-srinivasan.jpg",
		"linkedin": "https://www.linkedin.com/in/madhumathi-srinivasan-158a15244/",
		"testid": "madhumathi"
	},
	"mainak": {
		"name": "Mainak Khan",
		"currentDesignation": "Student",
		"education": "Metallurgical and Materials Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/mainak-khan-88a850178/",
		"testid": "mainak"
	},
	"manu": {
		"name": "Manu Aatitya R P",
		"currentDesignation": "Embedded Software Engineer, Nymble",
		"education": "Mechanical Engineering, 2021, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/manu-aatitya-r-p-78457412b/",
		"testid": "manu"
	},
	"miruthula": {
		"name": "Miruthula J",
		"jspfDesignation": "Associate",
		"currentDesignation": "ECE, 2023, NIT Trichy",
		"education": "ECE, 2023, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-miruthula-jayapraksham.jpeg",
		"linkedin": "https://www.linkedin.com/in/miruthula-jayapraksham-92943819b/",
		"testid": "miruthula"
	},
	"monica": {
		"name": "Monica Roy Chowdhury",
		"currentDesignation": "Analyst - Oil Operations, Trafigura",
		"education": "B.Arch, NITT",
		"pronouns": PRONOUNS.SHE,
		"image": "img-monica-roy.jpeg",
		"linkedin": "https://www.linkedin.com/in/monica-roy-chowdhury-046660128/",
		"testid": "monica"
	},
	"mrishana": {
		"name": "Mrishana Biswas",
		"currentDesignation": "Associate, ZS",
		"jspfDesignation": "Associate",
		"education": "B.Tech, EEE, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-mrishana.jpeg",
		"linkedin": "https://www.linkedin.com/in/mrishana-biswas/",
		"testid": "mrishana"
	},
	"mrperiasamy": {
		"name": "Mr. Periasamy",
		"currentDesignation": "Father of Jitheshraj Periasamy",
		"testid": "mrperiasamy"
	},
	"nagavalli": {
		"name": "HRSS Nagavalli Chatla",
		"currentDesignation": "Student",
		"education": "Electrical and Electronics Engineering",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/nagavalli-chatla-b53175213/",
		"testid": "nagavalli"
	},
	"nandhakishore": {
		"name": "Nandhakishore C S",
		"jspfDesignation": "Lead Associate",
		"currentDesignation": "Graduate Student, IIT Madras",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "img-nandhakishore-cs.jpeg",
		"linkedin": "https://www.linkedin.com/in/nandhakishorecs/",
		"testid": "nandhakishore"
	},
	"naresh": {
		"name": "Naresh B",
		"currentDesignation": "Student",
		"education": "Chemical Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/naresh-b-72a366202/",
		"testid": "naresh"
	},
	"nida": {
		"name": "Nida Maryam",
		"jspfDesignation": "Associate",
		"currentDesignation": "Mechanical, 2024, NIT Trichy",
		"education": "Mechanical, 2024, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-nida-maryam.jpeg",
		"linkedin": "https://www.linkedin.com/in/nida-maryam/",
		"testid": "nida"
	},
	"nirmal": {
		"name": "Nirmal Kumar",
		"testid": "nirmal"
	},
	"nithin": {
		"name": "Nithin Balaaji D V",
		"currentDesignation": "Manager, P&G",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-nithin-balaaji.png",
		"linkedin": "https://www.linkedin.com/in/nithin-balaaji/",
		"testid": "nithin"
	},
	"padmapriya": {
		"name": "Padmapriya Duraisamy",
		"currentDesignation": "Software Engineer, Google",
		"education": "ECE, 2014, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/pduraisamy/",
		"testid": "padmapriya"
	},
	"parvath": {
		"name": "Parvath C",
		"jspfDesignation": "Associate",
		"currentDesignation": "Civil, 2024, NIT Trichy",
		"education": "Civil, 2024, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-parvath-c.jpeg",
		"linkedin": "https://www.linkedin.com/in/parvath-c-nitt/",
		"testid": "parvath"
	},
	"pawan": {
		"name": "Pawan Kaipa",
		"currentDesignation": "State Manager, Gramin Healthcare",
		"education": "EEE, 2011, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-pawan-kaipa.jpeg",
		"linkedin": "https://www.linkedin.com/in/pawankaipa/",
		"testid": "pawan"
	},
	"pranav": {
		"name": "Pranav Chheda",
		"currentDesignation": "Sourcing Manager, Apple",
		"education": "Civil, 2013, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-pranav-chheda.jpeg",
		"linkedin": "https://www.linkedin.com/in/pchheda/",
		"testid": "pranav"
	},
	"praneash": {
		"name": "Praneashram Duraimurugan",
		"currentDesignation": "Operations Planning Analyst, American Airlines",
		"education": "Production, 2022, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-praneashram.jpeg",
		"linkedin": "https://www.linkedin.com/in/praneashram/",
		"testid": "praneashram"
	},
	"prashanthi": {
		"name": "Prashanthi Ravichandran",
		"currentDesignation": "Ph.D in Biomedical Engineering, Johns Hopkins",
		"education": "Mechanical, 2014, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-prashanthi-ravichandran.jpeg",
		"linkedin": "https://www.linkedin.com/in/prashanthi-ravichandran-96a363a0/",
		"testid": "prashanthi"
	},
	"purnima": {
		"name": "Purnima U",
		"currentDesignation": "Design Engineer, Synopsys",
		"education": "EEE, 2021, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-purnima-unnikrishnan.jpeg",
		"linkedin": "https://www.linkedin.com/in/purnima-unnikrishnan/",
		"testid": "purnima"
	},
	"rachana": {
		"name": "Rachana Ramchand",
		"currentDesignation": "Chief Investment Officer's team, CDC Group plc",
		"education": "CSE, 2012, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/rachanaramchand/",
		"testid": "rachana"
	},
	"raghu": {
		"name": "Raghu Ram SS",
		"currentDesignation": "Sr. Product Manager, Amazon",
		"education": "Civil, 2014, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/raghuramss/",
		"testid": "raghu"
	},
	"rahul": {
		"name": "Rahul Bhope",
		"currentDesignation": "PhD Student, UC Irvine",
		"education": "ICE, 2020, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-rahul-bhope.jpeg",
		"linkedin": "https://www.linkedin.com/in/rahul-bhope-3a0976130/",
		"testid": "rahul"
	},
	"rharshvardhan": {
		"name": "R Harshvardhan",
		"currentDesignation": "Student",
		"education": "Civil Engineering",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/harshavardhan-r-a1ab6524b/",
		"testid": "rharshvardhan"
	},
	"ruchika": {
		"name": "Ruchika Sahu",
		"currentDesignation": "Student, Christ University",
		"jspfDesignation": "Associate",
		"education": "BBA, Christ University",
		"pronouns": PRONOUNS.SHE,
		"image": "img-ruchika.jpeg",
		"linkedin": "https://www.linkedin.com/in/ruchika-sahu-b23695288/",
		"testid": "ruchika"
	},
	"saik": {
		"name": "Sai Karthik K A",
		"currentDesignation": "Technology Analyst - AI, Citi, Pune",
		"education": "Civil Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/kasaikarthik/",
		"testid": "saik"
	},
	"sarath": {
		"name": "Sarath Haridas",
		"testid": "sarath"
	},
	"saurabh": {
		"name": "Saurabh Kumar Jha",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/sneha-koley/",
		"testid": "saurabh"
	},
	"senthoorvel": {
		"name": "Senthoorvel P",
		"jspfDesignation": "Associate",
		"currentDesignation": "Student, NIT Trichy",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/senthoorvel-p/",
		"testid": "senthoorvel",
		"image": "img-senthoorvel.jpeg"
	},
	"shakti": {
		"name": "Shakti Chaudhari",
		"jspfDesignation": "Senior Associate",
		"currentDesignation": "Mech, MMCOE, 2022",
		"education": "BE, Mech, Marathwada Mitra Mandal's College of Engineering, Pune",
		"pronouns": PRONOUNS.HE,
		"image": "img-shakti-chaudhari.jpeg",
		"linkedin": "https://www.linkedin.com/in/shakti-chaudhari-1392121a4",
		"testid": "shakti"
	},
	"shiwang": {
		"name": "Shiwang Singh",
		"currentDesignation": "Technology Analyst, Citi",
		"education": "Civil Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "",
		"testid": "shiwang"
	},
	"shreyas": {
		"name": "K Shreyas",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/shreyas-k-029b35110",
		"testid": "shreyas"
	},
	"shriya": {
		"name": "Shriya Suriyanarayanan",
		"currentDesignation": "MBA, Ross School of Business",
		"education": "ICE, 2018, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/shriyasu/",
		"testid": "shriya"
	},
	"shurya": {
		"name": "Shurya Kumar",
		"currentDesignation": "MTS4, Nutanix",
		"jspfDesignation": "Associate",
		"education": "CSE, 2017, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-shurya-kumar.jpeg",
		"linkedin": "https://www.linkedin.com/in/shurya-kumar-82023798/",
		"testid": "shurya"
	},
	"sidchan": {
		"name": "Siddhant Chandra",
		"currentDesignation": "UPSC Aspirant",
		"jspfDesignation": "Lead Associate",
		"education": "Civil, 2016, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-siddhant-chandra.jpeg",
		"linkedin": "https://www.linkedin.com/in/siddhant-chandra-5a8a0667/",
		"testid": "sidchan"
	},
	"siddhant": {
		"name": "Siddhant Chandra",
		"currentDesignation": "Public Policy Designer/Researcher, Dialogue and Development Commission Of Delhi",
		"education": "Civil, 2016, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/siddhant-chandra-5a8a0667/",
		"testid": "siddhant"
	},
	"siva": {
		"name": "Sivaranjani Subramanian",
		"jspfDesignation": "Founder and Executive Director",
		"currentDesignation": "Software Engineer, Thoughtworks UK",
		"education": "CSE, 2015, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-sivaranjani-subramanian.jpeg",
		"linkedin": "https://www.linkedin.com/in/sranjani",
		"testid": "siva"
	},
	"sivaraj": {
		"name": " B K Sivaraj",
		"jspfDesignation": "Associate",
		"currentDesignation": "Metallurgical and Materials Engineering",
		"education": "Metallurgical and Materials Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "img-sivaraj-bk.jpeg",
		"linkedin": "https://www.linkedin.com/in/sivaraj-b-k-b56123152/",
		"testid": "sivaraj"
	},
	"sneha": {
		"name": "Sneha Menon",
		"currentDesignation": "Content Strategist, Stacker",
		"education": "B.Arch, NITT",
		"pronouns": PRONOUNS.SHE,
		"image": "img-sneha-menon.jpeg",
		"linkedin": "https://www.linkedin.com/in/menonsneha/",
		"testid": "sneha"
	},
	"snehak": {
		"name": "Sneha Koley",
		"currentDesignation": "Student",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"linkedin": "https://www.linkedin.com/in/sneha-koley/",
		"testid": "snehak"
	},
	"snigdha": {
		"name": "Snigdha Nagarajan",
		"testid": "snigdha"
	},
	"soundarya": {
		"name": "Soundarya Balasubramani",
		"currentDesignation": "Writer, Self-employed",
		"education": "Chemical, 2017, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/soundarya-balasubramani/",
		"testid": "soundarya"
	},
	"sowmya": {
		"name": "Sowmyasri Muthupandi",
		"currentDesignation": "Data Engineer at Meta",
		"education": "Production, 2014, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-sowmyasri-muthupandi.jpeg",
		"linkedin": "https://www.linkedin.com/in/sowmyasri/",
		"testid": "sowmya"
	},
	"srikants": {
		"name": "Srikant Sharma",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.HE,
		"linkedin": "https://www.linkedin.com/in/srikanth-v-2a74b117a",
		"testid": "srikants"
	},
	"srinivas": {
		"name": "Srinivas R",
		"currentDesignation": "Business Operations Associate at ZS",
		"education": "Civil Engineering, 2021, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/srinivasrnitt/",
		"testid": "shiwang"
	},
	"srivalli": {
		"name": "Srivalli Samineni",
		"currentDesignation": "Senior Associate, VAHDAM",
		"education": "Architecture, 2016, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-srivalli-samineni.jpeg",
		"linkedin": "https://www.linkedin.com/in/srivallisamineni/",
		"testid": "srivalli"
	},
	"subhasree": {
		"name": "G Subhasree",
		"currentDesignation": "Student",
		"education": "Computer Science and Engineering",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/subhasree-g-62a7a51a4/",
		"testid": "subhasree"
	},
	"sudharshan": {
		"name": "Sudharshan Suresh",
		"currentDesignation": "PHD in Robotics, Carnegie Mellon University",
		"education": "ICE, 2017, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/sudharshansuresh/",
		"testid": "sudharshan"
	},
	"suyash": {
		"name": "Suyash S. Damir",
		"currentDesignation": "MSc in Chemical and Bioengineering, ETH Zurich",
		"education": "B.Tech, Chemical Engineering, NITT",
		"pronouns": PRONOUNS.HE,
		"image": "img-suyash-damir.jpg",
		"linkedin": "https://www.linkedin.com/in/suyashsachindamir/",
		"testid": "suyash"
	},
	"swapnali": {
		"name": "Swapnali Patil",
		"jspfDesignation": "Associate",
		"currentDesignation": "Student, Srishti Manipal Institute",
		"education": "B.Des, Srishti Manipal Institute of Art Design and Technology",
		"pronouns": PRONOUNS.SHE,
		"linkedin": "https://www.linkedin.com/in/swapnalipatil10/",
		"testid": "swapnali",
		"image": "img-swapnali.jpeg"
	},
	"swedha": {
		"name": "Swedha S",
		"currentDesignation": "PhD Candidate at TU/e",
		"education": "B.Tech, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-swedha-s.png",
		"linkedin": "https://www.linkedin.com/in/swedha-s/",
		"testid": "swedha"
	},
	"tarun": {
		"name": "Tarun Kumaar",
		"jspfDesignation": "Associate",
		"currentDesignation": "Associate, PwC",
		"education": "B.Tech, MME, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-tarun.jpeg",
		"linkedin": "https://www.linkedin.com/in/tarun-kumaar-mk/",
		"testid": "tarun"
	},
	"thivyaa": {
		"name": "Thivyaa Mohan",
		"jspfDesignation": "Associate",
		"currentDesignation": "Civil, 2023, NIT Trichy",
		"education": "Civil, 2023, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-thivyaa-mohan.jpeg",
		"linkedin": "https://www.linkedin.com/in/thivyaamohan-nitt/",
		"testid": "thivyaa"
	},
	"vaikundaraman": {
		"name": "Vaikundaraman T S",
		"jspfDesignation": "Associate",
		"currentDesignation": "Metallurgical and Materials Engineering",
		"education": "Metallurgical and Materials Engineering",
		"pronouns": PRONOUNS.HE,
		"image": "img-vaikundaraman-t-s.jpeg",
		"linkedin": "https://www.linkedin.com/in/vaikundaraman-t-s-50aaa0224/",
		"testid": "vaikundaraman"
	},
	"varshith": {
		"name": "Varshith Mahanthi",
		"jspfDesignation": "Associate",
		"currentDesignation": "ECE, 2024, NIT Trichy",
		"education": "ECE, 2024, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-varshith-mahanthi.jpg",
		"linkedin": "https://www.linkedin.com/in/varshith-mahanthi-7ab202214/",
		"testid": "varshith"
	},
	"veera": {
		"name": "Veera dharshana N",
		"jspfDesignation": "Associate",
		"currentDesignation": "Architecture, 2025, NIT Trichy",
		"education": "Architecture, 2025, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "img-veera.jpeg",
		"linkedin": "https://www.linkedin.com/in/veeradharsana/",
		"testid": "veera"
	},
	"vibhak": {
		"name": "Vibha Kaushik",
		"currentDesignation": "Student",
		"education": "Production Engineering",
		"pronouns": PRONOUNS.SHE,
		"linkedin": "https://www.linkedin.com/in/vibha-kaushik",
		"testid": "vibhak"
	},
	"viswesh": {
		"name": "Viswesh Warrier",
		"currentDesignation": "Architectural Graduate, fitzpatrick+Partners",
		"education": "Architecture, 2017, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/visweshkwarrier/",
		"testid": "viswesh"
	},
	"vivek": {
		"name": "Vivek Shah",
		"jspfDesignation": "Advisor",
		"currentDesignation": "VP, Goldman Sachs",
		"education": "CSE, 2008, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-vivek-shah.jpeg",
		"linkedin": "https://www.linkedin.com/in/viveknshah/",
		"testid": "vivek"
	},
	"vrindaa": {
		"name": "Vrindaa S",
		"currentDesignation": "PHD, MIT",
		"education": "MME, 2016, NIT Trichy",
		"pronouns": PRONOUNS.SHE,
		"image": "",
		"linkedin": "https://www.linkedin.com/in/vrindaa-s-34646185/",
		"testid": "vrindaa"
	},
	"wahaj": {
		"name": "Wahaj Siddiqui",
		"currentDesignation": "Economist, Airfinity",
		"education": "Chemical, 2016, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-wahaj-siddiqui.jpeg",
		"linkedin": "https://www.linkedin.com/in/wahajsid/",
		"testid": "wahaj"
	},
	"zafar": {
		"name": "Zafar Hussain",
		"currentDesignation": "Strategy Lead, ICC",
		"education": "Production, 2010, NIT Trichy",
		"pronouns": PRONOUNS.HE,
		"image": "img-zafar-hussain.jpeg",
		"linkedin": "https://www.linkedin.com/in/zafarhussainsm/",
		"testid": "zafar"
	}
}


