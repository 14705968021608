import { useEffect, useState } from "react"

const getWindowSize = () => {
	const {innerHeight: height, innerWidth: width} = window
	return{
		width, height
	}
}

const useWindowDimensions = () => {
	const [windowSize, setWindowSize] = useState(getWindowSize())

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(getWindowSize())
		}

		window.addEventListener("resize", handleResize)

		return (() => {
			window.removeEventListener("resize", handleResize)
		})
	},[])

	return windowSize
}

export default useWindowDimensions