import React, { useEffect } from "react"

const CampusAmbassador = () => {
	useEffect(() => {
		document.title = "Campus Ambassador"
	})
	return (
		<div className="page" data-testid="campus-ambassador">
			<div className="col-8 page__container">
				<div className="campusAmb__container-title">
					JSPF Campus Ambassador Program
				</div>
				<hr/>
				<div className="campusAmb__container-subtitle">
					Overview
				</div>
				<div className="campusAmb__container-text">
					<ul>
						<li>JSPF has empowered 1000s of students so far across various institutes such as NIT Trichy, IIT Roorkee, NIT Calicut, Madras Institute of Technology, Savitribai Phule University, Anna University, Jamia Millia Islamia University, DOT School of Design, Symbiosis Institute of Design, etc. We aim to bring that opportunity and impact to as many undergraduate students in India as possible</li>
						<li>JSPF Campus Ambassador program empowers you to introduce JSPF to your university and unleash your potential and the potential of your fellow students, right from your first year in college</li>
						<li>We aim to form a community that expands beyond the horizons of our universities to facilitate growth and foster change. The community consists of JSPF Finalists, Mentors, Panelists and Alumni from various prestigious institutions</li>
					</ul>
				</div>
				<div className="campusAmb__container-subtitle">
					Why JSPF Campus Ambassador Program
				</div>
				<div className="campusAmb__container-text">
					<ul>
						<li>JSPF Campus Ambassador program is an extraordinary opportunity for students who like to go beyond academics and take leadership roles, explore new things, and create wider impact</li>
						<li>It gives you a platform to network with people from various colleges in different fields and learn from a network of distinguished alumni who were past mentors and panelists for the scholarship</li>
					</ul>
				</div>
				<div className="campusAmb__container-subtitle">
					What are your responsibilities?
				</div>
				<div className="campusAmb__container-text">
					<ol>
						<li>Sharing posts from JSPF Social Media Pages – on respective college social media pages and groups. You can check out our <a href='https://www.facebook.com/jrscholarship.org' target='_blank' rel='noreferrer'>Facebook</a>, <a href='https://www.instagram.com/jrscholarship_org/' target='_blank' rel='noreferrer'>Instagram</a>, <a href='https://www.linkedin.com/company/jrscholarship-org/' target='_blank' rel='noreferrer'>LinkedIn</a>, and <a href='https://www.youtube.com/channel/UCYHa8wxe8ocmamq5VLmUm4w' target='_blank' rel='noreferrer'>YouTube</a> profiles</li>
						<li>Promote our specific events to the college community (Mentor Office Hours, Socials , Mid Year Checkpoint etc)</li>
						<li>Be our brand ambassador and cheerleader for JSPF on campus </li>
						<li>Provide feedback on initiatives and their impact in your college</li>
						<li>Ideate and drive ideas to build a budding community of go-getters in your college under the JSPF umbrella</li>
					</ol>
				</div>
				<div className="campusAmb__container-subtitle">
					What do you get?
				</div>
				<div className="campusAmb__container-text">
					<ol>
						<li>You’ll be recognized as the JSPF Campus Ambassador of your college which can be added to your resume and LinkedIn profiles</li>
						<li>An opportunity to work in proximity with one of the fastest growing interdisciplinary community in India and learn from youth leaders across the country</li>
						<li>Build a network with a large community of JPSF members including alumni working in different sectors in MNCs, social sector, government and/or academia.</li>
						<li>Post an annual catch up on successful completion of the season, you will receive a certificate of leadership</li>
					</ol>
				</div>
				<div className="campusAmb__container-subtitle">
					How to apply?
				</div>
				<div className="campusAmb__container-text">Please fill out this expression of interest form and our team will reach out: 					<a
					className="page__link"
					href="https://forms.gle/daMvbZFtUNdLcA396"
					target="_blank"
					rel="noreferrer"
				>
					Apply Here
				</a></div>
				<div className="campusAmb__container-subtitle">
					Contact Us
				</div>
				<div className="campusAmb__container-text">
					You can reach out to us with your queries at <a className="page__link" target="_blank"
						rel="noreferrer" href="mailto:info@jrscholarship.org">info@jrscholarship.org</a> or contact us through WhatsApp at <a className="page__link" target="_blank" rel="noreferrer" href="https://wa.me/+919626883116">+919626883116</a>
				</div>
			</div>
		</div>
	)
}


export default CampusAmbassador