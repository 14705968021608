import Modal from "react-modal"
import React from "react"
import DOMPurify from "dompurify"

const CalendarModalPopup = (props) => {
	return (
		<div>
			<Modal
				isOpen={props.isOpen}  
				onRequestClose={() => props.setModalOpen(false)}
				ariaHideApp={false}
				className={"calendar-modal"}
			>
				<h2>{props.title}</h2>
				<button className="calendar-modal__close-button" onClick={() => props.setModalOpen(false)}><img src={require("../assets/icons/x.png")} className="button-image"/></button>
				<div className="calendar-modal__content-div" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(props.description)}}></div>
			</Modal>
		</div>
	)
}

export default CalendarModalPopup