import * as React from "react"
import { useEffect } from "react"
import { Link } from "react-router-dom"
import { applicationStages } from "utils/ApplicationStages"

function MidYearCheckpoint() {
	const midYearCheckpoint = applicationStages.find(stage => stage.name === "Mid year checkpoint")
	const midYearCheckpointDate = midYearCheckpoint?.date

	useEffect(() => {
		document.title = "Mid-year checkpoint"
	}, [])
	return (
		<div data-testid="midYearCheckpoint" className="page">
			<div className="page__container col-8">
				<p>
					Our mid-year checkpoint is designed to help keep our prospective applicants on track and provide guidelines where necessary. Drafts submitted as a part of this mandatory checkpoint will receive feedback from our designated mentors within a stipulated time period. Applicants can then incorporate the feedback that they deem reasonable to frame their final application.
					<br />
					<br />
					All interested first-year undergraduate students <strong>must</strong> take part in this mid-year checkpoint. Students who have not participated in the checkpoint will not be allowed to submit their final application.
				</p>
				<div className="midyearcheckpoint__container">
					<p><font size="6">Follow the steps below to complete the mid-year checkpoint</font></p>
					<br />
					<div>
						<p className="page__step-header"><font color="#425e13">Step 1:</font> Write drafts of 2 out of 4 eligible requirements</p>
						<p className="midyearcheckpoint__step-des">The 4 eligible requirements are: Statement of purpose, &apos;For the community&apos; essay, &apos;For the society&apos; essay and Project abstract. Project abstract must follow the <a href="https://docs.google.com/document/d/1jDEvNTYc7HkrRM4T0UcfL0vjXsPAF1JIOaaz-tw1b18/edit?usp=sharing" className="page__link" target='_blank' rel='noreferrer'>recommended template</a>. You can check the prompts for the essay <Link to="/requirements" className="page__link" data-testid="link-to-requirements">by clicking here</Link></p>
						<br />
					</div>
					<div>
						<p className="page__step-header"><font color="#425e13">Step 2:</font> Save your drafts as a Google document</p>
						<p className="midyearcheckpoint__step-des">To create a new Google document, go to <a href="https://docs.google.com" className="page__link" target='_blank' rel='noreferrer'>docs.google.com</a>, click on &apos;Blank&apos; under &apos;Start a new document&apos;</p>
						<br />
						<p className="midyearcheckpoint__step-des">Note: You might require a Google/Gmail account for this step.</p>
						<br />
					</div>
					<div>
						<p className="page__step-header"><font color="#425e13">Step 3:</font> Make sure the document is titled in the format JSPF_MYC{new Date().getFullYear()}_&lt;Your Name&gt;</p>
						<p className="midyearcheckpoint__step-des">For example, JSPF_MYC{new Date().getFullYear()}_Ramu</p>
						<br />
					</div>

					<div>
						<p className="page__step-header"><font color="#425e13">Step 4:</font> Share the document with <a className="page__link" href="mailto:jitheshrajscholarship@gmail.com">jitheshrajscholarship@gmail.com</a></p>
						<p className="midyearcheckpoint__step-des">Make sure that it is shared providing &apos;Editing&apos; privileges to the above email.</p>
						<br />
					</div>
				</div>
				<p className="midyearcheckpoint__deadline"><u>Deadline:</u> {midYearCheckpointDate} 11:59 pm IST</p>
			</div>
		</div>
	)
}

export default MidYearCheckpoint
