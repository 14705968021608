import React, { useEffect } from "react"

function PlagiarismAndCitation() {
	useEffect(() => {
		document.title = "Plagiarism and Citation"
	})
	return (
		<div className="page" data-testid="plagiarism-and-citation">
			<div className="col-8 page__container">
				<div className="plagiarism__container-title">
					Plagiarism and Citation
				</div>
				<div className="plagiarism__container-text">
					<p>JSPF will automatically disqualify any student who submits plagiarised material in their final application. You can avoid plagiarism by citing your sources. </p>
					<br />
					<p>The word ‘citation’ means ‘a quotation from or reference to a book, paper, or author, especially in a scholarly work’. The word to notice here is ‘reference’. You might have made a list of references at the end of an essay, assignment, or a submission of another kind. Citations are not very different - except they are done in a standard format with detailed information. There are two major reasons providing citations:</p>
					<ol>
						<li>You don’t take credit for something you didn’t do. That would be plagiarism, a serious offence.</li>
						<li>You provide evidence for some claim you’ve made in your writing.</li>
					</ol>
					<br />
					<p>In scientific writing, every claim you make needs evidence, all factual information you give (unless it’s a universally known fact) needs to be backed up by a reliable source. This is standard procedure followed by science so people can’t make up unsubstantiated claims. For instance, you might not believe me if I tell you there are 17-19 species of penguins. But when <a className="page__link" href="https://www.worldwildlife.org/species/penguin" target="_blank" rel="noreferrer">WWF says the same</a>, it’s reliable.</p>
					<br />
					<p>Once you’ve understood the ‘why’ of citation, the ‘how’ gets easier to fix. The main idea is:</p>
					<ol>
						<li>You make it loud and clear that some quote, picture, statistics, or any other idea, information or creation you’ve used in your writing/submission is not your own.</li>
						<li>You mention what information was got from where.</li>
						<li>You provide the source with enough details so that another person could, if they wish so, obtain the same information from the same source.</li>
					</ol>
					<br />
					<p>Now you need to decide what format you’ll follow. Here is a guide by JSPF giving suggestions for citation formats, as well as some tips on when and how to do it: <a className="page__link" href="https://docs.google.com/presentation/d/1rFnMHjK1aPqLrNK-ob4TgJ_1FaCUhgmle8s00yEpvE0/edit?usp=sharing" target="_blank" rel="noreferrer">Citing Sources</a></p>
					<p>Here’s an introduction to citations, made especially for students and beginners: <a className="page__link" href="https://lib.ku.edu/video-tutorials/citation-very-brief-introduction" target="_blank" rel="noreferrer">Citation: A (Very Brief Introduction)</a></p>
					<p>This one (by University of Texas) also gives tips on avoiding plagiarism: <a className="page__link" href="https://guides.lib.utexas.edu/c.php?g=539686&p=3694901" target="_blank" rel="noreferrer">Introduction to Citations</a></p>
					<br />
					<p>Here’s a life hack: <a className="page__link" href="https://zbib.org/" target="_blank" rel="noreferrer">ZoteroBib</a></p>
					<p>All you need to do is enter a URL or <a className="page__link" href="https://library.uic.edu/help/article/1966/what-is-a-doi-and-how-do-i-use-them-in-citations" target="_blank" rel="noreferrer">DOI</a>, and it gives you the citation in the format of your choice. </p>
					<p>It’s also good practice to manage your references using Zotero or Mendeley. This way you can generate your whole bibliography in one go. Apart from other benefits like adding notes, tags, shared libraries, etc. </p>
					<br />
					<p>You can find more tips on citations, plagiarism, and the whole process of doing a scientific project here: <a className="page__link" href="https://medium.com/jrscholarshipnitt/climbing-the-project-mountain-4b244853ad16" target="_blank" rel="noreferrer">Climbing the project mountain</a></p>
					<br />
				</div>
			</div>
		</div>
	)
}

export default PlagiarismAndCitation