import useWindowDimensions from "hooks/useWindowDimensions"
import React from "react"
import { Fade } from "react-slideshow-image"
import "react-slideshow-image/dist/styles.css"
import { IndicatorType } from "../utils/SlideshowIndicator"

const ImageDiv = (props) => {
	const Imagestyle = {
		backgroundImage: `url(${props.image.url})`,
		backgroundSize: "contain",
		backgroundRepeat: "no-repeat",
		backgroundPosition: "center"
	}
	const containerStyle = {
		width: props.containerWidth ? props.containerWidth : "400px",
		height: props.containerHeight ? props.containerHeight : "400px"
	}
	return (
		<div style={containerStyle} className="image-container slideshow__image-container">
			<div
				className="slideshow__image"
				style={Imagestyle}
			></div>
			<span>{props.image.caption}</span>
		</div>
	)
}

const SlideShow = (props) => {

	function getIndicators(indicatorType){
		if(!indicatorType){
			return false
		}
		switch (indicatorType.toUpperCase()){
		case IndicatorType.ORDERED:
			/* eslint-disable-next-line react/display-name */
			return (index => <div data-testid={ "ordered-" + (index+1) } className="indicator">{index + 1}</div>)
		case IndicatorType.UNORDERED:
			return true
		default:
			return false
		}
	}

	const windowSize = useWindowDimensions()
	let width
	if(windowSize.width <= 768){
		width = "94vw"
	}else{
		width = "35vw"
	}
	const slideshowStyle = {
		width: props.slideShowWidth ? props.slideShowWidth : width
	}
	return (
		<div data-testid="image-slideshow" className="slideshow" style={slideshowStyle}>
			<Fade indicators={ getIndicators(props.indicator) } duration={props.duration} pauseOnHover={true}>
				{props.Images.map((image, index) => {
					return (
						<div className="slideshow__each-fade" key={index}>
							<ImageDiv image={image} containerHeight={props.containerHeight} containerWidth={props.containerWidth}/>
						</div>
					)
				})}
			</Fade>
		</div>
	)
}

export default SlideShow