import React from "react"
import styles from "./Header.css"
import logo from "./../assets/logo-white.png"

function Header() {
	return (
		<div className="header" data-testid="header" style={styles}>
			<img className="logo" src={logo} alt="Logo" />
		</div>
	)
}

export default Header
