import { Stakeholders } from "utils/StakeholdersInfo"
import * as React from "react"
import StakeholderImageCard from "components/StakeholderImageCard"
import Stakeholder from "components/Stakeholder"

function Mentors() {
	React.useEffect(() => {
		document.title = "Mentors"
	})
	return (
		<div className="mentors__container col-9" data-testid="mentors">
			<div className="mentors__heading">Who is a Mentor?</div>
			<div className="mentors__intro page__light-text">
				<div>
					Mentors serve as guides for the JSPF application process via their
					weekly Office Hours and mentorship sessions, answer your questions on
					the WhatsApp Applicants&apos; group and provide feedback on your essay
					submissions. But your mentorship can be
					more than just that. Do reach out to your mentors via e- mail/Linkedin
					about any questions you may have related to your career,
					specialization or other interests. While no mentor can be a master of
					all trades, they can certainly share their perspective or connect you
					with an expert in their network if possible.
				</div>
				<p>
					Here are a few things you could seek guidance from your mentor for:
				</p>
				<ul className="page__list">
					<li>Brainstorm ideas for your project</li>
					<li>Request feedback on your preliminary essay drafts</li>
					<li>Request mock interviews</li>
					<li>Discuss blockers and seek help getting &apos;unstuck&apos;</li>
					<li>
						Set short-term goals and find an accountability partner in your
						mentor
					</li>
				</ul>
			</div>
			<div className="mentors__heading">Mentors for 2022-2023</div>
			<div className="mentors__row">
				<StakeholderImageCard
					{...Stakeholders.aradhana}
					className="mentors__column"
				/>
				<StakeholderImageCard
					{...Stakeholders.karthikeyan}
					className="mentors__column"
				/>
				<StakeholderImageCard
					{...Stakeholders.praneash}
					className="mentors__column"
				/>
			</div>
			<div className="mentors__heading">Past Mentors</div>
			<div className="mentors__row">
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2023-2024</div>
					<Stakeholder {...Stakeholders.ashwini} className="mentors__row" />
					<Stakeholder {...Stakeholders.kasarla} className="mentors__row" />
					<Stakeholder {...Stakeholders.monica} className="mentors__row" />
				</div>
			</div>
			<div className="mentors__row">
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2022-2023</div>
					<Stakeholder {...Stakeholders.Ajays} className="mentors__row" />
					<Stakeholder {...Stakeholders.anmol} className="mentors__row" />
					<Stakeholder {...Stakeholders.monica} className="mentors__row" />
					<Stakeholder {...Stakeholders.kasarla} className="mentors__row" />
					<Stakeholder {...Stakeholders.sneha} className="mentors__row" />
					<Stakeholder {...Stakeholders.suyash} className="mentors__row" />
				</div>
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2021-2022</div>
					<Stakeholder {...Stakeholders.chendur} className="mentors__row" />
					<Stakeholder {...Stakeholders.purnima} className="mentors__row" />
					<Stakeholder {...Stakeholders.rahul} className="mentors__row" />
					<Stakeholder {...Stakeholders.srivalli} className="mentors__row" />
				</div>
			</div>
			<div className="mentors__row">
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2020-2021</div>
					<Stakeholder {...Stakeholders.ashwini} className="mentors__row" />
					<Stakeholder {...Stakeholders.dhruv} className="mentors__row" />
					<Stakeholder {...Stakeholders.shriya} className="mentors__row" />
					<Stakeholder {...Stakeholders.viswesh} className="mentors__row" />
				</div>
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2019-2020</div>
					<Stakeholder {...Stakeholders.raghu} className="mentors__row" />
					<Stakeholder {...Stakeholders.lakshmi} className="mentors__row" />
					<Stakeholder {...Stakeholders.ayushi} className="mentors__row" />
					<Stakeholder {...Stakeholders.siddhant} className="mentors__row" />
				</div>
			</div>
			<div className="mentors__row">
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2018-2019</div>
					<Stakeholder {...Stakeholders.lakshmis} className="mentors__row" />
					<Stakeholder {...Stakeholders.cibi} className="mentors__row" />
					<Stakeholder {...Stakeholders.lakshmim} className="mentors__row" />
					<Stakeholder {...Stakeholders.sudharshan} className="mentors__row" />
				</div>
				<div className="mentors__column mentors__column--past-mentors">
					<div className="mentors__period">2017-2018</div>
					<Stakeholder {...Stakeholders.rachana} className="mentors__row" />
					<Stakeholder {...Stakeholders.padmapriya} className="mentors__row" />
					<Stakeholder {...Stakeholders.vrindaa} className="mentors__row" />
					<Stakeholder {...Stakeholders.soundarya} className="mentors__row" />
				</div>
				<div className="mentors__column mentors__column--past-mentors"></div>
			</div>
		</div>
	)
}

export default Mentors
