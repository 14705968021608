import React from "react"
import StakeholderImageCard from "./StakeholderImageCard"

function OrganiserImageCard(props) {
	return (
	//Changing props here so that it is displayed in the way we want
		<StakeholderImageCard {...props} education={props.currentDesignation} currentDesignation={props.jspfDesignation} />
	)
}

export default OrganiserImageCard