import * as React from "react"
import { Stakeholders } from "utils/StakeholdersInfo"
import StakeholderImageCard from "components/StakeholderImageCard"
import Stakeholder from "components/Stakeholder"

function Leadership() {
	return (
		<div className="page page__container col-9">
			<div data-testid="leadership">
				<h3 className="leadership__header">EXECUTIVE TEAM</h3>
				<div className="leadership__leader">
					<StakeholderImageCard {...Stakeholders.siva} />
					<p className="leadership__description">
						Sivaranjani started the Jitheshraj Scholarship in 2017 with a team
						of friends. While she is not working on the scholarship, she works
						as a software engineer at ThoughtWorks UK. A recipient of the
						British Council 70th Anniversary Scholarship and the UK Government
						Chevening Scholarship, she brings to the table her own experiences
						with scholarships, student programs, volunteer activities and
						startup consulting which continue to serve as guidelines for the
						Jitheshraj Scholarship. She received her postgraduate degree from
						King&apos;s College London and her undergraduate degree from NIT
						Trichy. Sivaranjani is responsible for the overall framework
						development and operations of the scholarship.
					</p>
				</div>
			</div>

			<div data-testid="patrons" className="leadership__group">
				<h3 className="leadership__header">PATRONS</h3>
				<div className="leadership__people">
					<Stakeholder {...Stakeholders.mrperiasamy} />
					<Stakeholder {...Stakeholders.drruckmani} />
				</div>
			</div>

			<div data-testid="special-advisors" className="leadership__group">
				<h3 className="leadership__header">SPECIAL ADVISORS</h3>
				<div className="leadership__people">
					<div>
						<Stakeholder {...Stakeholders.dranantharaman} />
						<Stakeholder {...Stakeholders.drbakthavatsalam} />
						<Stakeholder {...Stakeholders.drsubbaiyan} />
					</div>
				</div>
			</div>

			<div data-testid="past-volunteers" className="leadership__group">
				<h3 className="leadership__header">PAST VOLUNTEERS</h3>
				<div className="leadership__people">
					<div>
						<Stakeholder {...Stakeholders.chirag} />
						<Stakeholder {...Stakeholders.sarath} />
						<Stakeholder {...Stakeholders.deepaknd} />
						<Stakeholder {...Stakeholders.snigdha} />
						<Stakeholder {...Stakeholders.lakshmim} />
					</div>
					<div>
						<Stakeholder {...Stakeholders.miruthula} />
						<Stakeholder {...Stakeholders.deepan} />
						<Stakeholder {...Stakeholders.anupriya} />
						<Stakeholder {...Stakeholders.nirmal} />
						<Stakeholder {...Stakeholders.nida} />
						<Stakeholder {...Stakeholders.sivaraj} />
					</div>
				</div>
			</div>
		</div>
	)
}

export default Leadership
