export const Testimonials = {
	viekesh: {
		message: "The scholarship process is absolutely wonderful!! It provided me with a starting point in my college life and gave me a glimpse of what is required to achieve something significant.",
		by: "Ashutosh Verma, Applicant, JSPF 2018-19",
		testId: "ashutosh"
	},
	jspfpanelist: {
		message: "Experience with this cycle of JSPF has been very motivating to me. I derived great deal of inspiration from the zeal of the students who have done a great job throughout the process, exploring a lot more than what I had done in my first year. JSPF nudging them to form their opinion about what could be done for society would definitely prove to be a great start for many of them.",
		by: "Panelist, JSPF 2021-22",
		testId: "jspfpanelist"
	},
	ashutosh: {
		message: "It's not about the scholarship, but the things you do to achieve it. Writing SOPs, finishing MOOCs, participating in events within and outside the college. Whoa! It was an exciting journey as a whole.",
		by: "Viekash K S, Applicant, JSPF 2017-18",
		testId: "viekash"
	}
}