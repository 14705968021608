export const applicationStages = [
	{
		id: 1,
		name: "Interest form deadline",
		date: "September 30, 2024"
	},
	{
		id: 2,
		name: "Mid year checkpoint",
		date: "November 16, 2024"
	},
	{
		id: 3,
		name: "Application Deadline",
		date: "January 18, 2025"
	},
	{
		id: 4,
		name: "Shortlist Announcement",
		date: "February, 2025"
	},
	{
		id: 5,
		name: "Changemakers Challenge (Group Activity)",
		date: "March 7,8,9, 2024"
	},
	{
		id: 6,
		name: "Interviews",
		date: "March 15,16, 2024"
	}
]