import React from "react"
import NavItem from "./NavItem"

const DropDownMenu = (props) => {
	const style = {
		top: props.level > 1 ? (props.index)/props.nItems * 100+"%" : "",
		left: "100%"
	}
	return (
		<div
			style={!props.isMobileNavbar && props.level > 1 ? style: {}}
			className={props.level === 0 ? "nav-menu col-9" : "dropdown"}>
			{props.dropDownItems.map((item, index) => {
				return (
					<NavItem 
						setIsNavbarOpen={props.setIsNavbarOpen}	
						isMobileNavbar={props.isMobileNavbar}
						key={index}
						title={item.name} 
						level={props.level}
						hasChildren={!(item.children === null || item.children === undefined)}
						linkTo={item.routeTo}
						testId={item.testId}
						allOpen={props.allOpen}
					>
						{item.children
							&& <DropDownMenu 
								allOpen={props.allOpen}
								isMobileNavbar={props.isMobileNavbar}
								dropDownItems={item.children} 
								level={props.level+1}
								nItems={props.dropDownItems.length}
								index={index}></DropDownMenu>}
					</NavItem>
				)
			})}
		</div>
	)
}
export default DropDownMenu