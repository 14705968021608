import * as React from "react"
import { useEffect } from "react"

const ApplicationChecklist = () => {
	useEffect(() => {
		document.title = "Application Checklist"
	}, [])
	return (
		<div className="page" data-testid="checklist">
			<div className="page__container col-8 ">
				<h2>Checklist of all documents that you will submit as a part of your application:</h2>
				<p className="page__note">NOTE: There is a restriction on the document size/type during application submission. Prepare your documents accordingly.</p>
				<ol className="page__list">
					<li className="page__step-header">1000 character Introduction paragraph of yourself</li>
					<ul className="page__light-text page__light-text--app-check page__list page__list--inner">
						<li><em>A short paragraph to introduce yourself to the panelists. There are no restrictions other than the character limit.</em></li>
						<li><em>All text after the 1000th character will be ignored.</em></li>
					</ul>

					<li className="page__step-header"> Scanned copy of your Certificate of Participation or Merit of a contest</li>
					<ul className="page__light-text page__light-text--app-check page__list page__list--inner">
						<li><em>Scanned copy of the Participation or Merit certificate of the contest is to be submitted.</em></li>
						<li><em>Certificates of non-contest events like workshops, volunteering, etc., will not be accepted.</em></li>
						<li><em>We recommend choosing a contest that challenges you or is inclined to your interests.</em></li>
					</ul>

					<li className="page__step-header"> One page Abstract of your project</li>
					<ul className="page__light-text page__light-text--app-check page__list page__list--inner">
						<li><em>Project must be independent of your work for the contests you participate, if any. For example, if you participate in a Robotics contest and that is your JSPF contest criteria satisfied, you cannot submit the robot you built for the contest as your project. </em></li>
						<li><em>Project Abstract must follow the <a href="https://docs.google.com/document/d/1jDEvNTYc7HkrRM4T0UcfL0vjXsPAF1JIOaaz-tw1b18/edit?usp=sharing" className="page__link" target='_blank' rel='noreferrer'>recommended template</a>.</em></li>
					</ul>

					<li className="page__step-header"> Statement of Purpose essay</li>

					<li className="page__step-header"> &apos;For your Community&apos; essay</li>

					<ul className="page__light-text page__light-text--app-check page__list page__list--inner">
						<li><em>All essays must be submitted as pdfs.</em></li>
						<li><em>The text should be typed with &quot;Normal&quot; margin (2.54 cms on all four sides), font Calibri and font size 12. All of these options are available on Microsoft Word and other common word processor tools.</em></li>
						<li><em>There is a strict restriction on the word limit associated with each essay. Words beyond the prescribed limit will be ignored.</em></li>
					</ul>
				</ol>
			</div>
		</div>
	)
}

export default ApplicationChecklist
