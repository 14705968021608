import * as React from "react"
import {useEffect} from "react"
import { applicationStages } from "utils/ApplicationStages"

function Timeline({title}) {
	const timelineItems = applicationStages.map((stage) => 
		<div key={stage.id} data-testid={stage.id} className="timeline" style={{animationDelay: `${stage.id*0.2}s`}}>
			<h2>{stage.name}</h2>
			<p className="page__italic-text page__italic-text--timeline">{stage.date}</p>
			<br />
		</div>
	)

	useEffect(() => {
		document.title = title
	}, [])
	return (
		<div className="page page--timeline" data-testid="timeline">
			<div className="timeline timeline--header">
				<h1>Timeline 2023-2024</h1>
			</div>
			<br />
			<div>{timelineItems}</div>
			<br/>
			<div className="timeline timeline--header">
				<h1>Additional Support Events</h1>
				<h2>Mentorship sessions</h2>
				<p className="page__italic-text page__italic-text--timeline col-9">
					Designated JSPF mentors will provide 1-on-1 mentorship and group mentorship (Mentor Office Hours) during the entire application cycle. We highly encourage students to make use of these sessions to clarify their doubts and receive feedback on their application materials.
				</p>
				{/* <h2>JSPF Conclave</h2>
				<p className="page__italic-text page__italic-text--timeline col-9">
					JSPF Conclave is open to all first year undergraduate students. You are not required to be an
					applicant or to have participated in any of the past JSPF events in
					order to participate.
				</p>
				<p className="page__italic-text page__italic-text--timeline col-9">
					In order to make the most of JSPF Conclave, we encourage you to
					skim through the resources we shared in our Monthly Reading Lists so
					you can share your insights in the Reading Group session. If you
					haven&apos;t been receiving our Monthly Reading List, make sure to shoot
					us an email!
				</p> */}
			</div>
		</div>
	)
}

export default Timeline
