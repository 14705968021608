import React from "react"

function StakeholderWithImage(props) {
	return (
		<div className="stakeholderWithImage__container" data-testid={`${props.testid}`}>
			<a href={`${props.linkedin}`} target='_blank' rel="noreferrer"><img className="stakeholderWithImage__image" src={`images/${props.image}`}/></a>
		</div>
	)
}

export default StakeholderWithImage
