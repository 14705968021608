import React from "react"
import styles from "./Footer.css"
import { SocialIcon } from "react-social-icons"

function Footer() {
	return (
		<div className="footer" data-testid="footer" style={styles}>
			<div className="footer-subdiv">
				<SocialIcon style={{ height: 40, width: 40 }} fgColor="#000000" bgColor="#ffffff" target="_blank" url="https://facebook.com/jrscholarship.org" />
				<SocialIcon style={{ height: 40, width: 40 }} fgColor="#000000" bgColor="#ffffff" target="_blank" url="https://www.linkedin.com/company/jrscholarship-org/" />
				<SocialIcon style={{ height: 40, width: 40 }} fgColor="#000000" bgColor="#ffffff" url="mailto:info@jrscholarship.org" />
				<SocialIcon style={{ height: 40, width: 40 }} fgColor="#000000" bgColor="#ffffff" target="_blank" url="https://www.youtube.com/@jrscholarship_org" />
				<SocialIcon style={{ height: 40, width: 40 }} fgColor="#000000" bgColor="#ffffff" target="_blank" url="https://www.instagram.com/jrscholarship_org/" />
			</div>
			<div className="footer-subdiv">
				© JITHESHRAJ SCHOLARSHIP COPYRIGHT {new Date().getFullYear()}
			</div>
			<div className="footer-subdiv">
				AN INITIATIVE OF THE ACADEMY FOR HOLISTIC EDUCATION, AWARENESS AND
				DEVELOPMENT (AHEAD) TRUST. ALL RIGHTS RESERVED.
			</div>
		</div>
	)
}

export default Footer
