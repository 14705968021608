import React, {useState} from "react"
import { NavLink } from "react-router-dom"


const NavItem = (props) => {
	const [isOpen, setIsOpen] = useState(props.allOpen)
	const openDropDown = (event) => {
		if(props.isMobileNavbar){
			event.stopPropagation()
			setIsOpen(!isOpen)
		}
	}
	return(
		<div
			className={props.level === 0 ? "nav-item" : "dropDownItem" }
			onClick={openDropDown}
			onMouseEnter={() => !props.isMobileNavbar && setIsOpen(true)}
			onMouseLeave={() => !props.isMobileNavbar && setIsOpen(false)}
			data-testid={props.testId}>
			{props.hasChildren ?
				<div
					className={props.level === 0 ? "nav-links nav-links--mobile" : "nav-links dropdown-link" }>
					{props.title}
					{(props.isMobileNavbar || props.level > 0) && <span className="dropDownArrow">&#8250;</span>}
				</div>
				:
				<NavLink 
					onClick={() => props.setIsNavbarOpen(false)}
					to={props.linkTo} 
					className={props.level === 0 ? "nav-links nav-links--mobile" : "nav-links dropdown-link" }>
					{props.title}
				</NavLink>
			}
			
			{isOpen && props.children}
		</div>
	)
}

export default NavItem