import React from "react"

const Testimonial = (props) => {
	return (
		<div className="testimonial" data-testid={props.testId}>
			<p className="testimonial__message">{props.message}</p>
			<p className="testimonial__by">{props.by}</p>
		</div>
	)
}

export default Testimonial