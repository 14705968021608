import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import img_jitheshraj from "../assets/img-jitheshraj.jpeg"
import SlideShow from "components/Slideshow"
import { HomeImages } from "utils/SlideShowImages"
import TestimonialsSlideshow from "components/TestimonialsSlideshow"
import { IndicatorType } from "../utils/SlideshowIndicator"
import CalendarWithGoogleEvents from "components/CalendarWithGoogleEvents"
import ProcessDiagram from "components/ProcessDiagram"

const Home = () => {
	useEffect(() => {
		document.title = "Jitheshraj Scholarship for promising freshmen"
	}, [])
	return (
		<div className="page">
			<div data-testid="home">
				<div className="home__container home__container__bg home__container__bg--1 col-9">
					<h1 className="home__margin-text home__margin-text--heading">
						Exclusively for first-year undergraduate students in India
					</h1>
					<div className="home__container--with-image">
						<div className="home__left-cont">
							<div className="home__margin-text">
								<h1>A scholarship like no other</h1>
								<p className="page__light-text">
									Established in 2017, JSPF is a unique year-long mentorship
									programme that culminates into a competitive scholarship for
									first year undergraduate students in India.
								</p>
								<br />
								<p className="page__light-text">
									With learning and community at its core, JSPF provides a
									framework for students to explore and build their potential,
									so that they can be their best self and create change in the world after
									college.
								</p>
							</div>

							<br />
							<div className="home__learn-more-div">
								<Link data-testid="link-to-objectives" to="/our-objectives">
									<button className="home__learn-more"> Learn More </button>
								</Link>
							</div>
						</div>
						<div className="home__right-cont">
							<SlideShow
								Images={HomeImages}
								duration={2500}
								indicator={IndicatorType.UNORDERED}
							/>
						</div>
					</div>
				</div>
				<div
					data-testid="process"
					className="col-8 page__container"
				>
					<h1>Application process</h1>
					<div className="home__right-cont">
						<ProcessDiagram/>
					</div>
				</div>
				<div
					data-testid="scholarship-includes"
					className="home__container home__container__bg home__container__bg--2 col-9"
				>
					<div className="home__des">
						<br />
						<h2 className="home__header--cont-2">The Scholarship includes</h2>
						<p>
							<font color="#D5D5D5">A cash award of</font> 50,000 INR
						</p>
						<p>
							<font color="#D5D5D5">An opportunity to</font> network with
							accomplished alumni of various institutes across India
						</p>
						<p>
							<font color="#D5D5D5">Guidance from</font> other scholarship and
							fellowship winners
						</p>
						<p>
							<font color="#D5D5D5">In-house training to</font> prepare for and
							achieve world-class graduate level scholarships
						</p>
						<p>
							<font color="#D5D5D5">Access to</font> special events while you
							are a student (e.g. Global Alumni Meet)
						</p>
						<p>
							<font color="#D5D5D5">Access to</font> guest lectures organised
							exclusively for the JSPF Community
						</p>
						<strong>
							<p className="home__header--cont-2" style={{ padding: "10px 0" }}>
								Winner and Finalists of the scholarship become part of our
								exclusive community of changemakers
							</p>
						</strong>
						<br />
					</div>
				</div>
				<div
					data-testid="scholars"
					className="home__container home__container__bg home__container__bg--3 page__light-text col-9"
				>
					<div>
						<p>WHO ARE OUR SCHOLARS?</p>
						<h2 className="home__header--cont-3">
							<strong>Promising first year students</strong>
						</h2>
						<p>
							Through our rigourous selection process, we identify, encourage
							and celebrate in students
						</p>
						<ul className="page__list">
							<li>Curiosity to explore</li>
							<li>Discipline to persevere</li>
							<li>Will to make a difference</li>
							<li>Growth mindset</li>
						</ul>
					</div>
				</div>

				<div
					data-testid="testimonials"
					className="home__container home__container__bg home__container__bg--4 col-9"
				>
					<TestimonialsSlideshow />
				</div>

				<div
					data-testid="about-jitheshraj"
					className="home__container home__container--with-image home__container__bg home__container__bg--5 col-9"
				>
					<div className="home__left-cont">
						<h1 className="home__header--cont-5">About Jitheshraj</h1>
						<hr
							style={{
								width: "25vw",
								height: "1px",
								backgroundColor: "rgba(0, 0, 0, 0.13)",
								color: "rgba(0, 0, 0, 0.13)",
							}}
						/>
						<font color="#D5D5D5" className="home__margin-text">
							<p>
								Jitheshraj Periasamy was an undergraduate student of Computer
								Science and Engineering at NIT Trichy. He joined as a freshman
								in 2013. Smart, sensible and sensitive, Jitheshraj recognised
								his interests and goals as early as his first year in college.
								He aspired to become the students&apos; President and worked for
								it from day one. He strived to understand the nuances of the
								administration at NIT Trichy, recognised the problems at his
								university campus and improved the conditions in whatever way he
								could. More importantly, everyone remembers him as that one
								person who even amidst all the bustle and the pressure of
								college festivals, would personally make sure that people had
								their meal for the day. His ardent service to the causes he
								cared about left an eternal mark in the minds and hearts of
								everyone who knew him.
							</p>
							<p>
								This scholarship is a tribute to his qualities that we fondly
								remember, the qualities we want to instill in every student who
								steps into any Indian educational institute.
							</p>
						</font>
					</div>
					<div className="home__right-cont" style={{ padding: "25px 25px" }}>
						<img className="home__image" src={img_jitheshraj}></img>
					</div>
				</div>
				<div
					data-testid="annual-report"
					className="home__container home__container--with-image home__container__bg home__container__bg--6 col-9"
				>
					<div className="home__left-cont home__left-cont--report">
						Annual report 2022-2024 now available!
					</div>
					<div className="home__right-cont">
						<iframe
							src="https://drive.google.com/file/d/1IxoICrPmRi_EoeQGdbQXWcxKewXeAldJ/preview"
							width="640"
							height="480"
							allow="autoplay"
						></iframe>
					</div>
				</div>
				<div
					className="home__container home__calendar-div"
					data-testid="calendar-div"
				>
					<h2 className="home__header--cont-7">
						<strong>Upcoming Events</strong>
					</h2>
					<CalendarWithGoogleEvents />
				</div>
			</div>
		</div>
	)
}

export default Home
