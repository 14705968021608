import * as React from "react"
import { useEffect } from "react"
import req_1 from "../assets/requirements/img-req_1.jpg"
import req_2 from "../assets/requirements/img-req_2.jpg"
// import brownie from "../assets/requirements/brownie.png"

function Requirements() {
	useEffect(() => {
		document.title = "Requirements"
	}, [])
	return (
		<div className="page" data-testid="requirements">
			<div className="col-8 page__container">
				<div className="requirements__page">
					College proves to be a major turning point in every student&apos;s
					life: the avenues it opens, the freedom it grants and the resources at
					disposal, compels one to explore every path at the fork. And through
					this scholarship, we celebrate that exploratory spirit.
				</div>
				<div className="requirements__container">
					<div className="requirements__container-title">
						Before application deadline, every applicant of the scholarship
						should
					</div>
					<div className="requirements__container-text">
						<ol>
							<li>
								Participate in an online or onsite contest conducted during the
								current academic year.
							</li>
							<li>
								Compose a{" "}
								<a
									className="page__link"
									href="https://docs.google.com/document/d/1jDEvNTYc7HkrRM4T0UcfL0vjXsPAF1JIOaaz-tw1b18/edit?usp=sharing"
									target="_blank"
									rel="noreferrer"
								>
									1-page long project abstract
								</a>{" "}
								to a project that spans over a period of 4 weeks or more
								undertaken during the current academic year. It can be a hobby
								project or for an organisation of your choice. (Refer{" "}
								<a className="page__link" href="#brownie">
									{" "}
									Brownie Points Section
								</a>
								)
							</li>
							<li>Compose the following essays:</li>
						</ol>
					</div>
				</div>
				<div className="requirements__essays">
					<div className="requirements__container-title">Essays</div>
				</div>
				<div className="requirements__essays-img">
					<div className="requirements__img-container">
						<img className="requirements__img" src={req_1}></img>
						<p>For yourself</p>
						<div className="page__light-text">
							Explain the pivotal moments during your scholarship application
							process that helped you explore and achieve clarity about
							yourself. How do you intend to leverage the learnings from this
							process through the rest of your college life?
						</div>
						<div className="page__italic-text">Maximum 750 words</div>
					</div>
					<div className="requirements__img-container">
						<img className="requirements__img" src={req_2}></img>
						<p>For your community</p>
						<div className="page__light-text">
							Elucidate on a prevailing problem in your college / neighbourhood.
							What was its origin and what are its repercussions? Suggest an
							implementable solution to alleviate the issue.
						</div>
						<div className="page__italic-text">Maximum 500 words</div>
					</div>
				</div>
				<div
					id="brownie"
					className="requirements__container requirements__container--brownie"
					data-testid="brownie"
				>
					<div>
						<div className="requirements__container-title">Brownie Points</div>
						<div className="requirements__container-text">
							<div>
								Students can get additional points in their application by
								fulfilling one or more of the criterion listed below. Please
								note that these are not mandatory but additional criterion that
								can help you in your application journey.
								<ul className="page__list">
									<li>
										Projects pertaining to the domain of your study and
										related subjects are preferred.
									</li>
									<li>
										Projects that are interdisciplinary are preferred. The
										decision on which project is interdisciplinary is left to
										our panelists for the given year.
									</li>
									<li>
										Projects which are outside the academic mandate are
										preferred. If you are expected to do a project as a part of
										your academic curriculum, we encourage you to complete a
										different project as a part of your JSPF application.
									</li>
									<li>
										Project that is independent of the competition completed for
										JSPF is preferred. For example, if you participate in a
										robotics contest, then we encourage you to submit a separate
										project independent of your competition.
									</li>
									<li>
										Participation in JSPF events (Mentorship sessions, mentor
										office hours etc) will be reflected as brownie points in
										your application.
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

export default Requirements
