import * as React from "react"

function NotFound() {
	return (
		<div data-testid="404">
			404 - Not Found
		</div>
	)
}

export default NotFound
