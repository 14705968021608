import { Stakeholders } from "utils/StakeholdersInfo"
import * as React from "react"
import StakeholderImageCard from "components/StakeholderImageCard"
import Stakeholder from "components/Stakeholder"

function FinalistsAndScholars() {
	React.useEffect(() => {
		document.title = "Finalists And Scholars"
	}, [])
	return (
		<div className="finalists__container col-9" data-testid="finalists-and-scholars">
			<div className="finalists__heading">SCHOLARS</div>
			<div className="finalists__row">
				<StakeholderImageCard {...Stakeholders.hrishit}/>
				<StakeholderImageCard {...Stakeholders.madhumathi}/>
				<StakeholderImageCard {...Stakeholders.gayathri}/>
			</div>
			<div className="finalists__row">
				<StakeholderImageCard {...Stakeholders.harshinee}/>
				<StakeholderImageCard {...Stakeholders.jai} />
				<StakeholderImageCard {...Stakeholders.nithin}/>
				<StakeholderImageCard {...Stakeholders.swedha} />
			</div>
			<div className="finalists__heading">FINALISTS</div>
			<div className="finalists__row">
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2022-2023</div>
					<Stakeholder {...Stakeholders.anushka} className="finalists__row" />
					<Stakeholder {...Stakeholders.dharaneesh} className="finalists__row" />
					<Stakeholder {...Stakeholders.harsh} className="finalists__row" />
					<Stakeholder {...Stakeholders.kirthik} className="finalists__row" />
					<Stakeholder {...Stakeholders.saurabh} className="finalists__row" />
					<Stakeholder {...Stakeholders.senthoorvel} className="finalists__row" />
					<Stakeholder {...Stakeholders.snehak} className="finalists__row" />
					<Stakeholder {...Stakeholders.swapnali} className="finalists__row" />
				</div>
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2021-2022</div>
					<Stakeholder {...Stakeholders.vibhak} className="finalists__row" />
					<Stakeholder {...Stakeholders.rharshvardhan} className="finalists__row" />
					<Stakeholder {...Stakeholders.aadarsh} className="finalists__row" />
					<Stakeholder {...Stakeholders.vaikundaraman} className="finalists__row" />
					<Stakeholder {...Stakeholders.devb} className="finalists__row" />
					<Stakeholder {...Stakeholders.srikants} className="finalists__row" />
					<Stakeholder {...Stakeholders.kotilingesh} className="finalists__row" />
				</div>
			</div>
			<div className="finalists__row">
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2020-2021</div>
					<Stakeholder {...Stakeholders.akhilesh} className="finalists__row" />
					<Stakeholder {...Stakeholders.anushree} className="finalists__row" />
					<Stakeholder {...Stakeholders.ashwath} className="finalists__row" />
					<Stakeholder {...Stakeholders.ghantasala} className="finalists__row" />
					<Stakeholder {...Stakeholders.nagavalli} className="finalists__row" />
					<Stakeholder {...Stakeholders.mainak} className="finalists__row" />
					<Stakeholder {...Stakeholders.naresh} className="finalists__row" />
					<Stakeholder {...Stakeholders.parvath} className="finalists__row" />
				</div>
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2019-2020</div>
					<Stakeholder {...Stakeholders.sivaraj} className="finalists__row" />
					<Stakeholder {...Stakeholders.subhasree} className="finalists__row" />
					<Stakeholder {...Stakeholders.haemanth} className="finalists__row" />
					<Stakeholder {...Stakeholders.jeremiah} className="finalists__row" />
					<Stakeholder {...Stakeholders.shreyas} className="finalists__row" />
					<Stakeholder {...Stakeholders.abhishek} className="finalists__row" />
					<Stakeholder {...Stakeholders.nandhakishore} className="finalists__row" />
				</div>
			</div>
			<div className="finalists__row">
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2018-2019</div>
					<Stakeholder {...Stakeholders.akshaya} className="finalists__row" />
					<Stakeholder {...Stakeholders.anagha} className="finalists__row" />
					<Stakeholder {...Stakeholders.ashutosh} className="finalists__row" />
					<Stakeholder {...Stakeholders.jayamathangi} className="finalists__row" />
					<Stakeholder {...Stakeholders.madhav} className="finalists__row" />
					<Stakeholder {...Stakeholders.saik} className="finalists__row" />
				</div>
				<div className="finalists__column finalists__column--past-finalists">
					<div className="finalists__period">2017-2018</div>
					<Stakeholder {...Stakeholders.gudapati} className="finalists__row" />
					<Stakeholder {...Stakeholders.himanshi} className="finalists__row" />
					<Stakeholder {...Stakeholders.kishore} className="finalists__row" />
					<Stakeholder {...Stakeholders.manu} className="finalists__row" />
					<Stakeholder {...Stakeholders.shiwang} className="finalists__row" />
					<Stakeholder {...Stakeholders.srinivas} className="finalists__row" />
					<Stakeholder {...Stakeholders.darshan} className="finalists__row" />
				</div>
			</div>
		</div>
	)
}

export default FinalistsAndScholars
