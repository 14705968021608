import * as React from "react"

function OurCommitment() {
	return (
		<div className="page" data-testid="commitment">
			<div className="col-8 page__container">
				<div className="page__light-text">
					The Jitheshraj Scholarship is constantly striving to understand how we can help everyone whose lives we touch through the JSPF process. The first step to empowerment is access to information and opportunities and thus, JSPF makes the following commitment:
				</div>

				<div>
					<br />
					<br />
					<u>
						<font color="#2a2a2a">OUR ACCESSIBILITY STATEMENT:</font>
					</u>
					<br />
					<br />
					<p className="page__italic-text">
						Jitheshraj Scholarship for promising freshmen is committed to ideating &amp; executing strategies for linguistic accessibility to all its informational materials, suitable communicational accessibility to its various stakeholders, physical accessibility to all its in-person on-campus events &amp; logistic accessibility to all its digital (or otherwise) events. This is so individuals can always acquire needed information &amp; participate, in a manner that is relevant &amp; respectful. Our strategy for linguistic accessibility is to provide the information in varied formats so that it is engaging, informative &amp; relevant to each person. If you encounter any accessibility issue(s) regarding anything related to JSPF, send us an email at&nbsp;
						<a className="page__link" href="mailto:info@jrscholarship.org">info@jrscholarship.org</a>
						.
					</p>
				</div>

				<div className="page__light-text">
					<br />
					<br />
						We further take responsibility to strive for and celebrate diversity through inclusion among our student community; enabling equal opportunities for all first-year students, irrespective of their socio-economic conditions, and gender and linguistic identities:
				</div>
				<br />
				<br />
			</div>
			<div className="commitment__orange-layout">
				<div className="commitment__box">
					<div className="commitment__title">
						<div>
							You are welcome, you are represented, and
						</div>
						<br />
						<div>
							YOU matter!
						</div>
					</div>
					<div>
						The diversity you bring with yourself drives the innovation that our society needs.
					</div>
				</div>
			</div>
			<br />
			<br />
			<div className="col-8 page__container">
				<u>
					<font color="#2a2a2a">OUR DIVERSITY AND INCLUSION STATEMENT:</font>
				</u>
				<br />
				<br />
				<p className="page__italic-text">
					Jitheshraj Scholarship for promising freshmen, in its vision, celebrates the diversity you bring with your full selves as potential leaders &amp; change-makers. We, at JSPF, strive to nurture a culture where inclusiveness is instinctive in our spirit, and not just an initiative. India is home to a diverse student community with each having distinct lived experiences &amp; unique perspectives. We are committed to creating a scholarship process where all first-years, while wholly embracing their originality, have an equal opportunity to be a part of this journey, for the journey would be incomplete without YOU!
				</p>
			</div>
		</div>
	)
}

export default OurCommitment
