import React from "react"
import "./ProcessDiagram.css"

const ProcessDiagram = () => {
	return (
		<div className="diagram-container">
			<div className="circle">Step 1: Fill interest form</div>
			<div className="circle">Step 2: Attend mentorship sessions</div>
			<div className="circle">Step 3: Complete Mid year checkpoint</div>
			<div className="circle">Step 4: Submit application and get shortlisted</div>
			<div className="circle">Step 5: Complete group activity and interviews</div>
			<div className="circle">Step 6: Get selected as JSPF Scholar or Finalist</div>
		</div>
	)
}

export default ProcessDiagram
