import * as React from "react"
import { useState } from "react"
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"

import Header from "./components/Header"
import Navbar from "./components/Navbar"
import Footer from "./components/Footer"

import Apply from "./pages/Apply"
import Home from "./pages/Home"
import Faq from "./pages/Faq"
import Donate from "./pages/Donate"
import Workwithus from "./pages/WorkWithUs"
import Objectives from "pages/Objectives"
import OurCommitment from "pages/OurCommitment"
import FinalistsAndScholars from "pages/FinalistsAndScholars"
import Leadership from "pages/Leadership"
import Organisers from "pages/Organisers"
import Mentors from "pages/Mentors"
import SelectionAndInterviewPanel from "pages/SelectionAndInterviewPanel"
import GettingStarted from "pages/GettingStarted"
import Requirements from "pages/Requirements"
import Timeline from "pages/Timeline"
import MidYearCheckpoint from "pages/MidYearCheckpoint"
import Resources from "pages/Resources"
import PlagiarismAndCitation from "pages/PlagiarismAndCitation"
import ApplicationChecklist from "pages/ApplicationChecklist"
import NotFound from "pages/NotFound"
import useWindowDimensions from "hooks/useWindowDimensions"
import CampusAmbassador from "pages/CampusAmbassador"
import Ticker from "components/Ticker"
import ScrollToTop from "components/scrollToTop"

function App() {
	const windowSize = useWindowDimensions()
	const [isNavbarOpen, setIsNavbarOpen] = useState(false)
	let tabletBreakpoint = 768
	let isMobileNavbar = windowSize.width <= tabletBreakpoint
	return (
		<div className="app">
			<Router>
				{
					windowSize.width <= tabletBreakpoint
					&&
					<div className={isNavbarOpen ? "nav-sandwich hamburger-container change" : "nav-sandwich hamburger-container"}
						onClick={() => { setIsNavbarOpen(!isNavbarOpen) }}>
						<div className="bar1"></div>
						<div className="bar2"></div>
						<div className="bar3"></div>
					</div>
				}
				<Header />
				<Navbar
					allOpen={false} // this prop is only for testing
					className="navbar"
					isMobileNavbar={isMobileNavbar}
					setIsNavbarOpen={setIsNavbarOpen}
					isNavbarOpen={isNavbarOpen || windowSize.width >= tabletBreakpoint}
				/>
				<Ticker />
				<ScrollToTop />
				<Routes>
					<Route path="*" element={<NotFound />} />
					<Route path="/" element={<Home />}></Route>
					<Route path="/faq" element={<Faq />}></Route>
					<Route path="/work-with-us" element={<Workwithus />}></Route>
					<Route path="/donate" element={<Donate />}></Route>
					<Route path="/our-objectives" element={<Objectives />}></Route>
					<Route path="/our-commitment" element={<OurCommitment />}></Route>
					<Route
						path="/finalists-and-scholars"
						element={<FinalistsAndScholars />}
					></Route>
					<Route path="/leadership" element={<Leadership />}></Route>
					<Route path="/organisers" element={<Organisers />}></Route>
					<Route path="/mentors" element={<Mentors />}></Route>
					<Route
						path="/selection-and-interview-panel"
						element={<SelectionAndInterviewPanel />}
					></Route>
					<Route path="/getting-started" element={<GettingStarted />}></Route>
					<Route path="/requirements" element={<Requirements />}></Route>
					<Route
						path="/timeline"
						element={<Timeline title="Timeline" />}
					></Route>
					<Route path="/mid-year-checkpoint" element={<MidYearCheckpoint />}></Route>
					<Route path="/resources" element={<Resources />}></Route>
					<Route
						path="/plagiarism-and-citation"
						element={<PlagiarismAndCitation />}
					></Route>
					<Route
						path="/application-checklist"
						element={<ApplicationChecklist />}
					></Route>
					<Route
						path="/apply"
						element={<Apply />}
					></Route>
					<Route path="/campus-ambassador" element={<CampusAmbassador />}></Route>
				</Routes>
				<Footer />
			</Router>
		</div>
	)
}

export default App
