import * as React from "react"
import { Stakeholders } from "utils/StakeholdersInfo"
import OrganiserImageCard from "components/OrganiserImageCard"

function Organisers() {
	return (
		<div className="organisers__container" data-testid="organisers">
			<div className="organisers__heading">Founder&apos;s Office</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.anushree} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.gayatri} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.nandhakishore} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.ruchika} className="organisers__column" />
			</div>
			<div className="organisers__heading">Mentor & Panelist Relations</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.mrishana} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.nandhakishore} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.senthoorvel} className="organisers__column" />
			</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.shakti} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.swapnali} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.tarun} className="organisers__column" />
			</div>
			<div className="organisers__heading">Fundraising</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.darshan} className="organisers__column" />
				<OrganiserImageCard {...Stakeholders.veera} className="organisers__column" />
			</div>
			<div className="organisers__heading">Community Engagement</div>
			<div className="organisers__row">
				<OrganiserImageCard {...Stakeholders.anushree} className="organisers__column" />
			</div>
		</div>
	)
}

export default Organisers
