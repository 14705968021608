import React from "react"
import {Testimonials} from "../utils/Testimonials"
import { Slide } from "react-slideshow-image"
import Testimonial from "./Testimonial"

const TestimonialsSlideshow = () => {
	return (
		<Slide pauseOnHover={true}>
			{
				Object.keys(Testimonials).map((key, index) => (
					<Testimonial
						key={index}	
						{...Testimonials[key]}
					/>
				))
			}
		</Slide>
	)
}

export default TestimonialsSlideshow