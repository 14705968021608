import React from "react"
import StakeholderWithImage from "./StakeholderWithImage"

function StakeholderImageCard(props) {
	return (
		<div className="stakeholderImageCard__container" data-testid={`${props.testid}-card`}>
			<StakeholderWithImage {...props}/>
			<div className="stakeholderImageCard__card">
				<div> 
					<div className="stakeholderImageCard__card_name">{props.name}</div>
					<div className="stakeholderImageCard__card_pronouns">
						{Array.isArray(props.pronouns) ? props.pronouns.join(", ") : props.pronouns}
					</div>
				</div>
				<p className="stakeholderImageCard__card_designation">{props.currentDesignation}</p>
				<p className="stakeholderImageCard__card_education">{props.education}</p>
			</div>
		</div>
	)
}

export default StakeholderImageCard