import React from "react"
import Marquee from "react-fast-marquee"

const Ticker = () => {
	return (
		<Marquee className="ticker" gradient={false} pauseOnHover={true} speed={70}>
			<div className="ticker__div">
				Application deadline is January 18, 2025! <a data-testid="application-form" className="page__link" href="https://docs.google.com/forms/d/e/1FAIpQLSf6nFGMjipAOrUUkYNEH5-EKL-FKlKubIMQyqxRdHwiwg65tA/viewform" target="_blank" rel="noreferrer"> Submit your application here </a>today.
			</div>
			<div className="ticker__div">
				Our Annual report for 2022-2024 is now out! Read it <a data-testid="annual-report" className="page__link" href="https://drive.google.com/file/d/1IxoICrPmRi_EoeQGdbQXWcxKewXeAldJ/view?usp=drivesdk" target="_blank" rel="noreferrer">here</a>.
			</div>
		</Marquee>
	)
}

export default Ticker
