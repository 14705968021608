import React, { useEffect } from "react"

function Apply() {

	useEffect(() => {
		window.open("https://docs.google.com/forms/u/5/d/e/1FAIpQLSdB1hJnD5x149RnDG7M5VMx4yhkuFPb5KH8FMpMi9o4kdotcA/viewform", "_blank")
	}, [])

	return (
		<div className="page" data-testid="apply">
			<h3>Redirected</h3>
		</div>
	)
}

export default Apply
