export const HomeImages = [
	{
		url: "images/img-1.jpeg",
		caption: "Finalists, Organisers and Student leaders at NIT Trichy Orientation 2021 celebratory dinner",
	},
	{
		url: "images/img-2.jpeg",
		caption: "Mentorship session titled 'Design as a way of life' by Lakshmi K Ajayan, September 2019",
	},
	{
		url: "images/img-3.jpeg",
		caption: "Students brainstorming on UN Sustainable Development Goals at the JSPF Conclave, October 2019",
	},
	{
		url: "images/img-4.jpeg",
		caption: "Students being briefed about JSPF at A12 hall, NIT Trichy at the First Step event, August 2019",
	},
	{
		url: "images/img-5.jpeg",
		caption: "JSPF Scholar Nithin Balaaji DV being felicitated by Dr. Jerome and Dr. Anantharaman, August 2019",
	},
	{
		url: "images/img-6.jpeg",
		caption: "Second felicitation ceremony held at Barn Hall, NIT Trichy, August 2019",
	},
	{
		url: "images/img-7.jpeg",
		caption: "JSPF Finalists at the Global Alumni Meet held in Chennai, January 2020",
	},
	{
		url: "images/img-8.jpeg",
		caption: "JSPF Certificates provided to our top 8 applicants",
	},
	{
		url: "images/img-9.jpeg",
		caption: "JSPF finalists of the past and present year, along with Dr. Jerome and Dr. Anantharaman, August 2019",
	},
]

export const FAQImages = [
	{
		url: "images/img-faq-1.png",
	},
	{
		url: "images/img-faq-3.png",
	},
	{
		url: "images/img-faq-4.png",
	},
	{
		url: "images/img-faq-5.png",
	},
	{
		url: "images/img-faq-8.png",
	},
	{
		url: "images/img-faq-10.png",
	},
	{
		url: "images/img-faq-11.png",
	},
	{
		url: "images/img-faq-12.png",
	},
	{
		url: "images/img-faq-13.png",
	},
	{
		url: "images/img-faq-14.png",
	},
	{
		url: "images/img-faq-15.png",
	},
	{
		url: "images/img-faq-17.png",
	},
	{
		url: "images/img-faq-18.png",
	}
]