import React from "react"
import styles from "./Navbar.css"
import menuItems from "../utils/menuItems"
import DropDownMenu from "./DropDownMenu"
import { usePrev } from "../hooks/usePrev"
import { useLocation } from "react-router-dom"
import { GaTrack } from "../utils/GAUtil"


const Navbar = (props) => {

	const location = useLocation()
	const prevLocation = usePrev(location)

	if(prevLocation == null || ((prevLocation.pathname !== location.pathname))){
		GaTrack({
			hitType: "pageview",
			location: location.pathname,
			title: location.pathname
		})
	}

	return (
		<nav className="navbar" style={ styles } data-testid="navbar">
			{
				props.isNavbarOpen
				&&
				<DropDownMenu
					setIsNavbarOpen={ props.setIsNavbarOpen }
					isMobileNavbar={ props.isMobileNavbar }
					dropDownItems={ menuItems }
					allOpen={props.allOpen}
					level={ 0 }>
				</DropDownMenu>
			}
		</nav>
	)
}

export default Navbar
