import React, { useState } from "react"
import FullCalendar from "@fullcalendar/react"
import googleCalendarPlugin from "@fullcalendar/google-calendar"
import dayGridPlugin from "@fullcalendar/daygrid"	
import listPlugin from "@fullcalendar/list"
import CalendarModalPopup from "./CalendarModalPopup"

const CalendarWithGoogleEvents = () => {
	const [modalOpen, setModalOpen] = useState(false)
	const [modalDescription, setModalDescription] = useState("")
	const [modalTitle, setModalTitle] = useState("")
	const onEventClick = (info) => {
		setModalOpen(true)
		info.jsEvent.preventDefault() // don't let the browser navigate
		if(info.event.extendedProps.description){
			setModalDescription(info.event.extendedProps.description)
		}
		if(info.event.title){
			setModalTitle(info.event.title)
		}
	}
	return (
		<div>
			<FullCalendar
				height={"auto"}
				plugins={[googleCalendarPlugin, dayGridPlugin, listPlugin]}
				initialView="listWeek"
				googleCalendarApiKey={process.env.REACT_APP_API_KEY}
				eventSources={[
					{
						googleCalendarId: process.env.REACT_APP_CALENDAR_ID
					}
				]}
				headerToolbar={{
					start: "title", // will normally be on the left. if RTL, will be on the right
					center: "",
					end: "today dayGridMonth,listWeek prev,next" // will normally be on the right. if RTL, will be on the left
				}}
				eventClick={onEventClick}
			>
			</FullCalendar>
			<CalendarModalPopup isOpen={modalOpen} setModalOpen={setModalOpen} title={modalTitle} description={modalDescription}/>
		</div>
		
	)
}

export default CalendarWithGoogleEvents